import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from "redux";
// import { isArray } from 'lodash';
import {LevelButtonContainer} from "./styled";
import LevelButton from './LevelButton';
import {getTranslations} from "./../../../store/localization/selectors";

const mapStateToProps = (state) => ({
  translations: getTranslations(state)
})

class Tree extends Component {

  constructor(props) {
    super(props);
    this.props = props;

  }

  handleItemClick(item) {

    const {filterKey, selected, onFacetClick} = this.props;

    // const selectedArray = selected ? selected.split(' >> ') : [];
    // let value = item.key.split(' >> ');
    // value = value[level];
    // const isActive = selectedArray.indexOf(value) !== -1;

    // if (level < 1) {
    //
    //   if (selected === null) {
    //     onFacetClick({key: filterKey, value: item.key});
    //   } else if (selected !== null && isActive) {
    //     onFacetClick({key: filterKey, value: null});
    //   } else if (selected !== null && !isActive) {
    //     onFacetClick({key: filterKey, value: item.key});
    //   }
    //
    // } else {

      if (selected && (selected === item.key || selected.indexOf(item.key) !== -1)) {
        const values = item.key.split(' >> ');
        onFacetClick({key: filterKey, value: values.slice(0, -1).join(' >> '), remove: true});
      } else {
        onFacetClick({key: filterKey, value: item.key});
      }

    // }


  }

  render() {

    const {selected, data, field, onSearch, getInputRef, searchable, translations} = this.props;
    // const selectedArray = selected ? selected.split(' >> ') : [];

    return (
      <div className={'List'}>
        {searchable && (
          <div className={'List__Search'}>
            <input ref={ref => getInputRef(ref)} className={'List__SearchInput'} type='search' placeholder={translations["UI"]["Search"]}
                   onChange={e => onSearch(e.target.value)} onKeyUp={e => onSearch(e.target.value)}/>
          </div>
        )}
        <div className={'List__Content'}>
          {data.map((item, index) => {
            return (
              <LevelButtonContainer key={`${field}-${index}`}>
                <LevelButton active={selected === item.key} hideArrow={true} onClick={e => this.handleItemClick(item)} label={item.label} count={item.doc_count}/>
              </LevelButtonContainer>
            )
          })}
        </div>
      </div>
    );

    // return <div/>;

  }

}

export default compose(
  connect(mapStateToProps)
)(Tree);
