import React from 'react';
import styled from "styled-components";
import { useSelector } from 'react-redux'
import {useParams} from "react-router-dom";
import * as moment from 'moment';
import clip from "text-clipper";

// import {useTheme} from "../../../themes";
import { getConfig } from "./../../../store/settings/selectors";
import {getTranslations} from "./../../../store/localization/selectors";

// import Icon from "../../../components/App/Icon";
import ToggleFavoriteButton from "../../../components/User/ToggleFavoriteButton";
import ReactHtmlParser from 'react-html-parser';


const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 10px 0px 0px;
  padding: 15px 15px;
  /*background-color: ${props => props.isActive ? "#ffffff" : "#F8F9F9"};*/
  border: 1px solid #818181;
  border-radius: 12px;
  ${props => !props.isActive ? `
    &:hover{
      background-color: #ffffff;
    }
  ` : ""};



`;
const Subwrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

`;
const Left = styled.div`
  flex-basis: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
`;
const Center = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
`;
const Right = styled.div`
  flex-basis: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
`;

const UpperText = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: #000000;

  & em{
    font-style: normal;
    border-bottom: 2px solid #000000;
  }
`;
const TitleText = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #E4018E;
  padding: 6px 0px;

  & em{
    font-style: normal;
    border-bottom: 2px solid #000000;
  }
`;
const LowerText = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 23px;
  color: #000000;

  & em{
    font-style: normal;
    border-bottom: 2px solid #000000;
  }
`;
export const WrapperBottom = styled.div`
  width: 100%;
  text-align: right;

  div {
    display:inline-block;
    margin-left: 10px;
  }
`;
export const Tag = styled.div`
  display: inline-block;
  margin-left: 10px;
  background: #000;
  border-radius: 4px;
  padding: 1px 6px 1px 6px;
  font-size: 9px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffffff;
  background: #E4018E;

  &.bordered {
    background: transparent;
    border: 2px solid #707070;
  }
`;

export const Avatar = styled.div`
  width: 60px;
  height: 60px;
  background: ${props => props.corporate ? "#E4018E" : "#E4018E"};
  border-radius: 100px;
  margin-right: 10px;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  line-height: 14px;
`;


function EventResult ({item, isActive}) {

  // const theme = useTheme();
  const translations = useSelector(getTranslations);
  const date = item.options.plannings[0].dateRange;
  const config = useSelector(getConfig);
  let { lang } = useParams();

  return (
    <Wrapper isActive={isActive}>
      <Subwrapper>
        <Left>
          <Avatar corporate={item.type === "indoor"}>{(moment(date.start)).format('DD/MM') + ((moment(date.start)).format('DD/MM') !== (moment(date.end)).format('DD/MM') ? " "+(moment(date.end)).format('DD/MM') : "")}</Avatar>
        </Left>
        <Center>
          { date ?
            <UpperText>{(moment(date.start)).format('D MMMM YYYY')} | {(moment(date.start)).format('HH:mm')} {date.timezone}</UpperText>
          : ""}
          <TitleText>{ReactHtmlParser(clip(item.highlight && item.highlight["indexData.title."+lang].length > 0 ? item.highlight["indexData.title."+lang][0]: item.options.title[lang], 80, { html: true }))}</TitleText>
        { item.companyName ?
            <LowerText>{item.companyName}</LowerText>
          : ""}
        </Center>
        { config.enable_login && config.enable_favorites ?
          <Right>
            <ToggleFavoriteButton slug={item.id} area={"events"} size={{value: 30, unit:"px"}} colorOn={"#E4018E"} colorOff={"#dedede"} />
          </Right>
        : "" }
      </Subwrapper>
      {item.tags && item.tags.length ? (
        <WrapperBottom>
          {item.tags.map(tag => <Tag key={`${item.slug}-bottom-${tag}`}>{translations["UI"]["_badge-"+tag]}</Tag>)}
        </WrapperBottom>
      ) : null}
    </Wrapper>


  );

}

// EventResult.propTypes = {}

export default React.memo(EventResult);
