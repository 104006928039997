import React, { Component } from 'react';
import styled from "styled-components";

const Wrapper  = styled.div`
	overflow: hidden;
	${props => props.isLoading ? `
		visibility: hidden;
		height: 1px;
	` : `` }
`;
const Row  = styled.div`
	overflow: hidden;
	display: flex;
	flex-wrap: wrap;
`;
const Col = styled.div`
	white-space: normal;
	position: relative;
	background-color: #f1f1f1;
	width: ${props => props.width }px;
	height: ${props => props.height}px;
	margin-right: ${props => props.marginRight}px;
	margin-bottom: ${props => props.marginBottom}px;

	& img {
		width: 100%;
		height: 100%;
		border: 0px;
		opacity: ${props => props.isLoading ? 0 : 1 };
		transition: opacity 0.30s ease-in-out;
	}


`;



export default class FlowershelfGallery extends Component {
	constructor(props) {
		super(props);
		this.nodeContainer = React.createRef();
		this.state = {
			images: props.images,
			gutter: (props.gutter ? props.gutter : 0),
			rowHeight: (props.rowHeight ? props.rowHeight : null),
			fullRows: (props.fullRows ? props.fullRows : null),
			orderedLoading: (props.orderedLoading ? props.orderedLoading : null),

			containerWidth: null,
			isLoading: true
		};
	}

	componentDidMount(){
		this.setState({
			containerWidth: this.nodeContainer.current.offsetWidth,
			isLoading: false
		});

		// window.addEventListener('resize', () => this.handleResize());
	}

	// componentDidUpdate(nextProps, nextState){
	// 	console.log(nextProps);
	// 	console.log(nextState);
	// 	// if(nextProps.images !== this.props.images ||
	// 	// 	nextProps.gutter !== this.state.gutter ||
	// 	// 	nextProps.rowHeight !== this.props.rowHeight ||
	// 	// 	nextProps.fullRows !== this.props.fullRows ||
	// 	// 	nextProps.orderedLoading !== this.props.orderedLoading){
	// 	// 	this.setState({
	// 	// 		images: nextProps.images,
	// 	// 		gutter: (nextProps.gutter ? nextProps.gutter : 0),
	// 	// 		rowHeight: (nextProps.rowHeight ? nextProps.rowHeight : null),
	// 	// 		fullRows: (nextProps.fullRows ? nextProps.fullRows : null),
	// 	// 		orderedLoading: (nextProps.orderedLoading ? nextProps.orderedLoading : null),
	// 	//
	// 	// 		isLoading: true
	// 	// 	}, () => this.updateGrid());
	// 	// }
	//
	// }

	checkContainerWidth(usedWidth){
		// var _this = this;
		// if(this._divContainer.getBoundingClientRect().width < usedWidth){
		// 	setTimeout(function(){
		// 		_this._render(Math.floor(_this._divContainer.getBoundingClientRect().width));
		// 	},1);
		// 	return true;
		// }else{
		return false;
		// }
	}

	updateGrid(){
		if(this.state.containerWidth){
			let jsonimages = this.props.images;

			for(let iRow = 0; iRow < jsonimages.length; iRow++){
				let arrElem = jsonimages[iRow];
				let rowWidthAtSameHeight = 0;

				if (this.props.rowHeight === null) {

					for(let iElem = 0; iElem < arrElem.length; iElem++){
						let jsonElement = arrElem[iElem];

						rowWidthAtSameHeight += ((jsonElement.width) * 1000 / (jsonElement.height));
					}


					// console.log("---------");
					// console.log("this.state.containerWidth: " + this.state.containerWidth);
					// console.log("rowWidthAtSameHeight: " + rowWidthAtSameHeight);
					// console.log("rowNewHeight: " + (this.state.containerWidth - (this.state.gutter * (arrElem.length - 1))) * 1000 / rowWidthAtSameHeight);
					// console.log("---");

					let rowNewHeight = (this.state.containerWidth - (this.state.gutter * (arrElem.length - 1))) * 1000 / rowWidthAtSameHeight;
					for(let iElem = 0; iElem < arrElem.length; iElem++){
						let jsonElement = arrElem[iElem];

						if (iElem !== 0 - 1) {
							jsonElement.isFirst = true;
							// if(this.checkContainerWidth(this.state.containerWidth)){
							// 	return;
							// }
						}else{
							jsonElement.isFirst = false;
						}
						if (iElem !== arrElem.length - 1) {
							jsonElement.isLast = true;
						}else{
							jsonElement.isLast = false;
						}

						jsonElement.newHeight = Math.floor(rowNewHeight);
						// console.log("A: " + arrElem[iElem].width);
						// console.log("B: " + Math.floor(rowNewHeight));
						// console.log("C: " + arrElem[iElem].height);
						//
						// console.log(Math.floor((arrElem[iElem].width * rowNewHeight / arrElem[iElem].height)));
						jsonElement.newWidth = Math.floor((arrElem[iElem].width * rowNewHeight / arrElem[iElem].height));
						if (iElem !== arrElem.length - 1) {
							jsonElement.newMarginRight = this.state.gutter;
						}else{
							jsonElement.newMarginRight = 0;
						}
						if (iRow !== jsonimages.length - 1) {
							jsonElement.newMarginBottom = this.state.gutter;
						}

						jsonimages[iRow][iElem] = jsonElement;
					}
				}else{

					for(let iElem = 0; iElem < arrElem.length; iElem++){
						rowWidthAtSameHeight += ((arrElem[iElem].width) * this.props.rowHeight / (arrElem[iElem].height));
					}


					let subrowCount = Math.round(rowWidthAtSameHeight / this.state.containerWidth);
					let subrowLength = 0;
					let subrowBreaks = [];
					let subrowNewLength;

					if (!this.props.fullRows) {
						subrowNewLength = this.state.containerWidth;
					}else{
						subrowNewLength = rowWidthAtSameHeight / subrowCount;
					}
					let subrowBreakpoint = subrowNewLength;
					let currentInRow = 0;
					for(let iElem = 0; iElem < arrElem.length; iElem++){

						let precSubrowLength = subrowLength;
						subrowLength += ((arrElem[iElem].width) * this.props.rowHeight / (arrElem[iElem].height)) + this.state.gutter;

						let subgutteredSubrowLength = subrowLength - this.state.gutter;
						currentInRow++;
						if (subgutteredSubrowLength > subrowBreakpoint) {
							if ((subgutteredSubrowLength - subrowBreakpoint > subrowBreakpoint - precSubrowLength || !this.props.fullRows) && currentInRow > 1) {
								subrowBreaks.push(iElem-1); //prima
								currentInRow--;
								iElem--;
							}else{
								subrowBreaks.push(iElem); //dopo
							}

							if (!this.props.fullRows) {
								subrowLength = 0;
							}else{
								subrowBreakpoint += subrowNewLength;
							}
							currentInRow = 0;
						}else if (iElem === arrElem.length - 1){
							subrowBreaks.push(arrElem.length-1);
						}

					}


					let startPoint = 0;
					for(let k = 0; k < subrowBreaks.length; k++){
						let endPoint = subrowBreaks[k];

						let rowWidthAtSameHeight = 0;
						for(var iElem = startPoint; iElem <= endPoint; iElem++){
							rowWidthAtSameHeight += ((arrElem[iElem].width) * 1000 / (arrElem[iElem].height));
						}

						let rowNewHeight;


						if (!this.props.fullRows && k === subrowBreaks.length - 1) {
							if (startPoint === endPoint) {
								let elementWidth = ((arrElem[startPoint].width) * this.props.rowHeight / (arrElem[startPoint].height));
								if (elementWidth >= this.state.containerWidth) {
									rowNewHeight = (this.state.containerWidth) * 1000 / rowWidthAtSameHeight;
								}else{
									rowNewHeight = this.props.rowHeight;
								}
							}else{
								rowNewHeight = this.props.rowHeight;
							}
						}else{
							rowNewHeight = (this.state.containerWidth - (this.state.gutter * (endPoint - startPoint))) * 1000 / rowWidthAtSameHeight;
						}


						let currentRowWidth = 0;
						for(let iElem = startPoint; iElem <= endPoint; iElem++){
							let jsonElement = arrElem[iElem];

							let newWidth = Math.floor((arrElem[iElem].width * rowNewHeight / arrElem[iElem].height));
							currentRowWidth += newWidth;

							if (iElem === startPoint) {
								jsonElement.isFirst = true;
								// if(this.checkContainerWidth(this.state.containerWidth)){
								// 	return;
								// }
							}else{
								jsonElement.isFirst = false;
							}
							if (iElem === endPoint) {
								jsonElement.isLast = true;
								if (currentRowWidth !== this.state.containerWidth && iElem !== subrowBreaks[subrowBreaks.length-1]) {
									newWidth += this.state.containerWidth - currentRowWidth;
								}
							}else{
								jsonElement.isLast = false;
							}

							jsonElement.newHeight = Math.floor(rowNewHeight);
							jsonElement.newWidth = newWidth;

							if (iElem !== endPoint) {
								jsonElement.newMarginRight = this.state.gutter;
								currentRowWidth += this.state.gutter;
							}else{
								jsonElement.newMarginRight = 0;
							}
							if (iElem <= subrowBreaks[subrowBreaks.length-2] || iRow < jsonimages.length-1) {
								jsonElement.newMarginBottom = this.state.gutter;
							}
							jsonimages[iRow][iElem] = jsonElement;
						}
						startPoint = endPoint+1;

					}
				}

			}

			return jsonimages;
			// this.setState({
			// 	images: jsonimages,
			// 	isLoading: false
			// });
		}
	}

	render(){
		// console.log("renderizzo");
		let jsonImages = this.updateGrid();

		return(
			<Wrapper isLoading={this.state.isLoading} ref={this.nodeContainer}>
				{this.state.containerWidth !== null ?
					<>
						{jsonImages.map((row, rowIndex) => (
							<Row key={rowIndex}>
								{row.map((element, elementIndex) => (
									<Col
										key={elementIndex}
										isLoading={this.state.isLoading}
										width={element.newWidth}
										height={element.newHeight}
										marginRight={element.newMarginRight}
										marginBottom={element.newMarginBottom}
									>
										{element.content}
									</Col>
								))}
							</Row>
						))}
					</>
					:
					<>Loading...</>
				}
			</Wrapper>
		);

	}
}
