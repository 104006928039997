import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { withTheme } from './../../themes';
import { getLayout } from './../../store/settings/selectors';

import {LAYOUT_XS, LAYOUT_SM} from './../../settings';

import Icon from "./../App/Icon";
import UserTag from "./../User/UserTag";


const Wrapper  = styled.div`
  height: 46px;
  margin: 10px 15px 10px 15px;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  width: calc(100% - 30px);
`;

const Left = styled.div`
  flex-basis: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
`;
const Center = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;


  font-family: ${props => props.textFont};
  font-size: ${props => props.textSize}px;
  font-weight: ${props => props.textWeight};
  line-height: ${props => props.textLineHeight}px;
  letter-spacing: ${props => props.textLetterSpacing}px;
  text-transform: ${props => props.textTransform};
`;
const Right = styled.div`
  flex-basis: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
`;

const MobileHeader = ({
  backlink = null,
  title,
  theme
}) => {
  const layout = useSelector(getLayout);

  if(layout === LAYOUT_XS || layout === LAYOUT_SM){
    return (
      <Wrapper>
        <Left>
          {backlink ?
            <Link to={backlink}>
              <Icon
                size={{
                 value: 30,
                 unit: "px"
                }}
                name={theme.icons.set.menuitemPrev}
                color={theme.mobileheader.icon.color}
                background={theme.mobileheader.icon.background}
              />
            </Link>
          : null }
        </Left>
        <Center
          textFont={theme.mobileheader.textFont}
          textSize={theme.mobileheader.textSize}
          textWeight={theme.mobileheader.textWeight}
          textLineHeight={theme.mobileheader.textLineHeight}
          textLetterSpacing={theme.mobileheader.textLetterSpacing}
          textTransform={theme.mobileheader.textTransform}
          textColor={theme.mobileheader.textColor}
        >
          {title}
        </Center>
        <Right>
          <UserTag
            color={theme.topbar.textColor}
            buttonVariant={theme.topbar.buttonVariant}
            size={{"value": 46, "unit": "px"}}
          />
        </Right>
      </Wrapper>
    );
  }else{
    return null;
  }
}


export default withTheme(MobileHeader)
