import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTheme} from "./../../themes";
import styled from "styled-components";
import {setStatus} from "./../../store/settings/actions";
import {getUser} from "./../../store/auth/selectors";
import {DOMAINS} from "./../../domains";

// import LoginButton from "./../User/LoginButton";
// // import LogoutButton from "./../User/LogoutButton"


const Wrapper = styled.section`
  /* padding: 10px; */
  position: relative;
  color: ${props => props.color};
  display: flex;
  align-items: center;
`;
const Avatar = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  height: ${props => props.size.value+props.size.unit};
  width: ${props => props.size.value+props.size.unit};
  border-radius: 100%;
  background-size: cover;
  background-position: center;
  background-clip: content-box;

  // cursor: pointer;
  /* border: 2px solid ${props => props.color}66; */

  background-color: #dedede;
  background-image: url(${props => props.pic});
`;
const AvatarMDM = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  height: ${props => props.size.value+props.size.unit};
  width: ${props => props.size.value+props.size.unit};
  border-radius: 100%;
  background-size: cover;
  background-position: center;
  background-clip: content-box;

  cursor: pointer;
  /* border: 2px solid ${props => props.color}66; */

  background-color: #E4018E;
  color: #fff;

  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: Montserrat,Arial,sans-serif;
  font-size: 14px;
  font-weight: 600;
`;
const Text = styled.div`
  flex-grow: 1;
  margin: 0px 10px;
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  cursor: pointer;
`;


const UserTag = ({color = null, full = false, size = {"value": 30, "unit": "px"} }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const theme = useTheme();

  if(DOMAINS[window.location.hostname].enable_login){
    return (
      <Wrapper color={color} onClick={() => dispatch(setStatus({
        isUserpanelOpen: true
      }), [dispatch])}>
        {user ?
          <>
            {DOMAINS[window.location.hostname].tenant === "MDM" ?
              <AvatarMDM size={size}>
                {user.firstname.length>0 ? user.firstname[0] : ""}{user.lastname.length>0 ? user.lastname[0] : ""}
              </AvatarMDM>
            :
              <Avatar size={size} pic={user.avatar} color={color}/>
            }
            {full ?
              <Text>{user.firstname + " " + user.lastname}</Text>
            :
              null
            }
          </>
        :
          <>
            <Avatar size={size} pic={theme.placeholders.user} color={color} />
            {full ?
              <Text>Login</Text>
            :
              null
            }
          </>
        }
      </Wrapper>
    )
  }else{
    return (
      <></>
    )
  }
}

export default UserTag;
