import React, {Component} from 'react';
import styled from 'styled-components';
import List from "./List";
import Detail from "./Detail";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0,0,0,0.46);
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

// const Container = styled.span`
//   width: 100%;
//   position: relative;
//   border-radius: 10px;
//   background: #f2f4f4;

//   > button {
//     position: absolute;
//     right: 0;
//     top: 0;
//   }
// `;

class Modal extends Component {

  state = {
    view: 'list'
  }

  setView(view) {
    const { token } = this.props;
    if (token)
      this.setState({view});
    else
      this.props.onLogin();
  }

  bindFormEvents() {

    const { token } = this.props;

    this.$accountRef.addEventListener('submit::success', (response) => {

      this.props.onSubmit();

      const { roles } = response.detail[0].data;
      if (
        (this.state.view === 'basic' && token) ||
        (this.state.view === 'MMPuserPremium_' && roles.indexOf('MMPuserPremium') !== -1)
      ) {
        setTimeout(() => {
          this.props.onClose();
        }, 1000);
      }

    })
  }

  render() {
    let { token, roles, canClose = true, onClose, pluginEnv, locale } = this.props;
    let { view } = this.state;
    let route = !roles || roles.length === 0 || view === 'basic' ? 'profile' : 'premium';

    /**
     * 01/09/21 - Luca
     * forzato view a 'profile' perché su MDM non esiste un profilo "pemium" come su Vinitaly
     */
    route = 'profile'

    return (
      <Wrapper>
        {view === 'list' ? (
          <List token={token} roles={roles} canClose={canClose} onClick={plan => this.setView(plan)} onClose={onClose}  />
        ) : (
          <Detail
            view={view === 'basic' ? 'Basic' : 'Premium3'}
            token={token}
            onBack={() => this.setView('list')}
            onClose={onClose}
            renderCallback={() => this.bindFormEvents()}
          >
            <vrf-account env={pluginEnv} locale={locale} ref={ref => this.$accountRef = ref} route={route} token={token.accessToken} />
          </Detail>
        )}
      </Wrapper>
    );
  }

}

export default Modal;
