import React, {useState} from 'react';
import styled from 'styled-components';
import {useTheme} from "./../../themes";
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from 'react-router-dom';
import {getLocale} from "./../../store/localization/selectors";
import {getLayout} from "./../../store/settings/selectors";
import {toggleFilters} from "../../store/settings/actions";

import {LAYOUT_SM, LAYOUT_XS} from './../../settings';

import Search from '../List/Sidebar/Search/Search';
import Icon from "./../App/Icon";
import Image from './../App/Image';
import {getList} from "../../store/bcard/selectors";
import Analytics from "../Analytics/Analytics";

const Wrapper = styled.div`
  position: relative;

  height: calc(100vh - 50px);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? `
    padding-left: 50%;
    padding-top: 20px;
  ` : ``};
`;

const ResultsList = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
`;

const Container = styled.div`
  width: calc(100% - 30px);
  margin: 0px 15px;
`;


const Result = styled.div`
  position: relative;
  width: 100%;
  margin: 10px 0px 0px;
  padding: 15px 15px;
  background-color: ${props => props.isActive ? "#ffffff" : "#EDEDED"};
  border-radius: 10px;
  ${props => !props.isActive ? `
    &:hover{
      background-color: #ffffff;
    }
  ` : ""};



`;
const Subwrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

`;
const Left = styled.div`
  flex-basis: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
`;
const Center = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
`;
const Right = styled.div`
  flex-basis: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
`;

const UpperText = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: #585858;

  & em {
    font-style: normal;
    border-bottom: 2px solid #585858;
  }
`;
const TitleText = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #7B3572;
  padding: 6px 0px;

  & em {
    font-style: normal;
    border-bottom: 2px solid #585858;
  }
`;
const LowerText = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 23px;
  color: #585858;

  & em {
    font-style: normal;
    border-bottom: 2px solid #585858;
  }
`;

// const Count = styled.div`
//   border-radius: 15px;
//   background: rgba(112, 62, 106, 0.11);
//   width: 40px;
//   height: 30px;
//   margin-right: 10;
//   text-align: center;
//   color: #703E6A;
//   font-weight: bold;
//   font-size: 15px;
//   padding-top: 6px;
// `;

const Avatar = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 20px;
`;


export default function Sidebar() {

  const dispatch = useDispatch();
  dispatch(toggleFilters(false));

  const theme = useTheme();
  const lang = useSelector(getLocale);
  const layout = useSelector(getLayout);
  const [search, setSearch] = useState(null);
  let list = useSelector(getList);

  list = list.filter(item => !search || item.surname.toLowerCase().search(search.toLowerCase()) > -1 || item.name.toLowerCase().search(search.toLowerCase()) > -1 || item.organization.toLowerCase().search(search.toLowerCase()) > -1);

  let {id} = useParams();

  return (
    <Wrapper layout={layout} id="wrapper">
      <Search
        hideToggleFilters={true}
        placeholder={`Cerca`}
        onChange={e => setSearch(e.target.value)}
      />
      <ResultsList>
        <Container>
          {list.map((item, index) => (
            <Link to={`/${lang}/bcards/${item.id}`} key={`bcard-list-${item.id}`}>
              <Result isActive={id === item.id}>
                <Subwrapper>
                  <Left>
                    <Avatar>
                      <Image src={item.photo ? item.photo : theme.placeholders.user} width={{"value": 60, "unit": "px"}}
                             height={{"value": 60, "unit": "px"}} fit="cover"/>
                    </Avatar>
                  </Left>
                  <Center>
                    {false ?
                      <UpperText>{"Lorem ipsum"}</UpperText>
                      : ""}
                    <TitleText>{item.name} {item.surname}</TitleText>
                    {true ?
                      <LowerText>{item.organization}</LowerText>
                      : ""}
                  </Center>
                  <Right>
                    {id === item.id && <Icon name="arrow-right-2" size={{value: 25, unit: 'px'}}/>}
                  </Right>
                </Subwrapper>
              </Result>
            </Link>
          ))}
        </Container>
      </ResultsList>
      <Analytics name="bcards-list" />
    </Wrapper>
  )
};
