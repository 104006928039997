import React from 'react'
import {Link} from "react-router-dom";
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactHtmlParser from 'react-html-parser';
import { useParams } from 'react-router-dom';
import Icon from "../../../components/App/Icon";

import { useTheme } from "./../../../themes";
import { getConfig } from "./../../../store/settings/selectors";
import {getTranslations} from "./../../../store/localization/selectors";

import ToggleFavoriteButton from "./../../../components/User/ToggleFavoriteButton";
import Image from "./../../../components/App/Image";


const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 10px 0px 0px;
  padding: 15px 15px;
  background-color: ${props => props.isActive ? "#ffffff" : (props.isSuggested ? "#FFF5E8" : "#EDEDED")};
  border-radius: 10px;
  ${props => !props.isActive ? `
    &:hover{
      background-color: #ffffff;
    }
  ` : ""};
`;
const Subwrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Left = styled.div`
  flex-basis: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
`;
const Center = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
`;
const Right = styled.div`
  flex-basis: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
`;

const UpperText = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: #585858;

  & em{
    font-style: normal;
    border-bottom: 2px solid #585858;
  }
`;
const TitleText = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.isSuggested ? "#FF8900" : "#7B3572"};
  padding: 6px 0px;

  & em{
    font-style: normal;
    border-bottom: 2px solid #585858;
  }
`;
const LowerText = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 23px;
  color: #585858;

  & em{
    font-style: normal;
    border-bottom: 2px solid #585858;
  }
`;
export const WrapperBottom = styled.div`
  width: 100%;
  text-align: right;

  div {
    display:inline-block;
    margin-left: 10px;
  }
`;
export const Tag = styled.div`
  display: inline-block;
  margin-left: 10px;
  background: #000;
  border-radius: 4px;
  padding: 3px 4px 4px 4px;
  font-size: 8px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #606060;
  background: #ebdfe7;

  &.bordered {
    background: transparent;
    border: 2px solid #707070;
  }
`;
export const Photo = styled.div`
  background: #ffffff;
  border-radius: 4px;
  margin-right: 10px;
  width: 70px;
  height: 100px;
  padding: 5px;

`;


function ProductResult({
    item,
    isActive,
    isFavorite,
    url,
    resultType
  }) {
  const config = useSelector(getConfig);
  const translations = useSelector(getTranslations);
  const theme = useTheme();
  let { lang } = useParams();
  return (

    <Wrapper isActive={isActive} isSuggested={resultType === "suggested"}>
      <Subwrapper>
        <Left>
          <Photo>
            <Image
              src={item.options.imageId ? item.options.imageId.src : theme.placeholders.product}
              alt="Photo"
              fit={"contain"}
              height={{"value": 100, "unit": "%"}}
            />
          </Photo>
        </Left>
        <Center>
          { item.options.exhibitor ?
            <UpperText>{ReactHtmlParser(item.options.exhibitor.name)}</UpperText>
          : ""}
          <TitleText isSuggested={resultType === "suggested"}>{ReactHtmlParser(item.highlight && item.highlight["indexData.name"].length > 0 ? item.highlight["indexData.name"][0] : item.options.name)}</TitleText>
        { item.options.category ?
            <LowerText>{ReactHtmlParser(item.options.category[lang])}</LowerText>
          : ""}
        </Center>
        { config.enable_login && config.enable_favorites ?
          <Right>
            {item.eventsCount > 0 ? <Link to={`${url}#events`}><Icon size={{value: 30, unit: 'px'}} name={'calendar-1'} color={'#079192'}/></Link> : null}
            <ToggleFavoriteButton slug={item.id} area={"products"} size={{value: 30, unit:"px"}} colorOn={"#7B3572"} colorOff={"#dedede"} />
          </Right>
        : "" }
      </Subwrapper>
      {item.tags && item.tags.length ? (
        <WrapperBottom>
          {item.tags.map(tag => <Tag key={`${item.slug}-bottom-${tag}`}>{translations["UI"]["_badge-"+tag]}</Tag>)}
        </WrapperBottom>
      ) : null}
    </Wrapper>

  );
}

ProductResult.propTypes = {
  item: PropTypes.object,
  isActive: PropTypes.bool,
  isFavorite: PropTypes.bool
}

export default React.memo(ProductResult)
