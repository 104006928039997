// import axios from "axios";
import {DOMAINS} from "./../domains";

export class TranslationsService {

  async getTranslation(lang){
    return new Promise((resolve, reject) => {
      if(DOMAINS[window.location.hostname].translations.url){
        fetch(DOMAINS[window.location.hostname].translations.url + 'ui_'+lang+'.json',{
          headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        }).then((response) => {
          return response.json();
        }).then((result) => {
          // console.log(result);
          resolve(result);
        });
      }else{
        resolve(null);
      }
    });
  }
}

export default new TranslationsService();
