import produce from "immer"
import { isEmpty } from 'lodash';
import { handleActions } from 'redux-actions';
import {setList, setFacets, setFilters, setCount, setFiltersSchema} from './actions'
import {getChildFilters} from "../../helpers";
import {RECORDS_PER_PAGE} from "../../settings";

const INITIAL_STATE = {
  items: [],
  page: 0,
  end: false,
  count: 0,
  count_initial: null,
  count_filters: 0,
  error: null,
  filters: {},
  filtersType: null,
  loading: false,
  timestamp: null,
  facets: {
    loading: false,
    data: {}
  },
  total_count: null,
  filtersSchema: null,
};

const reducer = handleActions({

  [setList.TRIGGER]: (state) => produce(state, draft => {
    draft.loading = true;
  }),

  [setList.SUCCESS]: (state, { payload }) => produce(state, draft => {

    const items = payload.reset || payload.page === 0 ? [...payload.items] : [...state.items,...payload.items];
    const isEnded = payload.items.length < RECORDS_PER_PAGE;

    draft.loading = payload.hasOwnProperty('isLoading') ? payload.isLoading : (!payload.timestamp ? state.loading : false);
    draft.items = items;
    draft.count = payload.count;
    draft.count_filters = payload.count;
    draft.end = isEnded;
    draft.page = isEnded ? state.page : payload.page;
    draft.timestamp = payload.timestamp;

    if (payload.resetSubarea) {
      draft.count_initial = draft.count;
    }

    if (draft.count_initial === null) {
      draft.count_initial = draft.count;
    }

  }),

  [setList.FAILURE]: (state, { payload }) => produce(state, draft => {
    draft.loading = false;
    draft.error = payload
  }),

  [setFacets.TRIGGER]: (state) => produce(state, draft => {
    draft.facets.loading = true;
  }),

  [setFacets.SUCCESS]: (state, { payload }) => produce(state, draft => {

    draft.facets.loading = false;
    if (payload.reset) {
      draft.facets.data = {};
    } else {
      draft.facets.data[payload.field] = payload.data;
    }

    if (payload.count !== undefined) {
      draft.count_filters = payload.count;
    }

  }),

  [setFilters.SUCCESS]: (state, { payload }) => produce(state, draft => {

    draft.filters = payload.filters;
    draft.filtersType = payload.filtersType;

    if (payload.reset) {
      draft.count = state.count_initial;
    }
    if (isEmpty(payload)) {
      draft.count_filters = draft.count;
    }

  }),

  [setCount.SUCCESS]: (state, { payload }) => produce(state, draft => {
    draft.total_count = payload.count;
  }),

  [setFiltersSchema.SUCCESS]: (state, { payload }) => produce(state, draft => {
    // const returnKeys = (object) => object.hasOwnProperty('properties') ? object.properties : returnKeys(object);
    const schema = payload = Object.keys(payload).reduce((acc, key) => {
      const item = payload[key]
      const childs = getChildFilters(item, key);
      return {
        ...acc,
        [key]: {
          ...item,
          childs
        }
      }
    }, {})
    draft.filtersSchema = schema;
    draft.filters = {};
    draft.facets = {
      loading: false,
      data: {}
    };

  }),

}, INITIAL_STATE);

export default reducer;
