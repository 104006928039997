import React, { useEffect } from 'react';
import styled from "styled-components";
import IconButton from "./Button";
import HeaderLogo from "./Logo";

const Wrapper = styled.div`
  width: 100%;
  max-width: 880px;
  margin: 0 auto;
  border-radius: 10px;
  background: #f2f4f4;
  padding: 30px 15px;
  max-height: calc(100vh - 30px);
  overflow: auto;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

// const Title = styled.div`
//     font-size: 33px;
//     color: #333333;
// `;

// const Top = styled.div`
//   text-align: center;
//   margin-bottom: 30px;

//   p {
//     margin-top: 5px;
//   }
// `;

// const Bottom = styled.div`
//   text-align: center;
//   margin-top: 30px;

//   > button {
//     margin: 0 auto;
//   }
// `;

// const FormBlock = styled.div`
//   background: #fff;
//   padding: 20px 30px;
//   margin-bottom: 10px;
//   border-radius: 10px;
//   border: 1px solid #EDEDED;
// `;

// const FormRow = styled.div`
//   display: flex;
//   margin: 20px 0;

//   label {
//     width: 40%;
//     border-bottom: 1px solid #9D9D9D;
//     padding-bottom: 5px;
//   }

//   input {
//     width: 60%;
//     border: none;
//     border-radius: none;
//     background: transparent;
//     font-family: inherit;
//     font-size: inherit;
//     border-bottom: 1px solid #DDDDDD;
//     margin-left: 10px;
//     padding-bottom: 5px;
//     color: #606060;
//   }
// `;



export default function Detail({ logoTheme, onClose, canBack, onBack, view, children, renderCallback }) {

  useEffect(() => {
    renderCallback()
  }, [renderCallback]);

  return (
    <Wrapper>
      <div style={{ position: 'relative '}}>
        <IconButton onClick={onClose} style={{ position: 'absolute', right: '20px', top: '-5px'}} type="close" />
        <IconButton onClick={onBack} style={{ position: 'absolute', left: 0, top: '-5px'}} type="back" />
        <Container>
          <Header>
            <HeaderLogo />
          </Header>
          {children}
        </Container>
      </div>
    </Wrapper>
  );

}
