import React from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
// import { useTheme } from './../../themes';

const Wrapper  = styled.div`
  overflow: hidden;

  & ul{
    list-style-type: none;
    padding-left: 15px;
  }
  & li{
    position: relative;
    line-height: 26px;
    font-size: 13px;
    font-weight: 400;
    color: #606060;
  }
  & li::before{
    content: "";
    display: block;
    position: absolute;
    top: 26px;
    left: -50%;
    width: 200%;
    border-top: 1px solid #7B357233;
  }
  & > ul{
    padding-left: 0px;
  }

`;

const List = ({
  variant = "default",
  children
}) => {
  // const theme = useTheme()
  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}

List.propTypes = {
  variant: PropTypes.string,
}

export default React.memo(List)
