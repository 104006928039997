import React, { Component } from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import { replaceStrWithObj } from "../../helpers";
import { isEmpty } from 'lodash';

import {withTheme} from './../../themes';
// import MobileTopbar from "../Layout/MobileTopbar";
// import Logo from "../App/Logo";
import Analytics from '../Analytics/Analytics';
import Sidebar from './Sidebar/Sidebar';
import Filters from '../Filters/Filters';

import {
  setFacets as companiesSetFacets,
  setList as companiesSetList,
  setFilters as companiesSetFilters,
  setFiltersTree as companiesSetFiltersTree,
  setFiltersSchema as companiesSetFiltersSchema,
} from "../../store/companies/actions";

import {
  setFacets as productsSetFacets,
  setList as productsSetList,
  setFilters as productsSetFilters,
  setFiltersTree as productsSetFiltersTree,
  setFiltersSchema as productsSetFiltersSchema,
} from "../../store/products/actions";

import {
  setFacets as casehistoriesSetFacets,
  setList as casehistoriesSetList,
  setFilters as casehistoriesSetFilters,
  setFiltersTree as casehistoriesSetFiltersTree,
  setFiltersSchema as casehistoriesSetFiltersSchema,
} from "../../store/casehistories/actions";

import {
  setFacets as eventsSetFacets,
  setList as eventsSetList,
  setFilters as eventsSetFilters,
  setFiltersTree as eventsSetFiltersTree,
  setFiltersSchema as eventsSetFiltersSchema,
} from "../../store/events/actions";

import {
  setFacets as subcompaniesSetFacets,
  setList as subcompaniesSetList,
  setFilters as subcompaniesSetFilters,
  setFiltersTree as subcompaniesSetFiltersTree,
  setFiltersSchema as subcompaniesSetFiltersSchema,
} from "../../store/subcompanies/actions";

import {
  setFacets as subcasehistoriesSetFacets,
  setList as subcasehistoriesSetList,
  setFilters as subcasehistoriesSetFilters,
  setFiltersTree as subcasehistoriesSetFiltersTree,
  setFiltersSchema as subcasehistoriesSetFiltersSchema,
} from "../../store/subcasehistories/actions";

import { setLocale } from "../../store/localization/actions";
import {
  setArea,
  setLevels,
  setResultsType,
  toggleFilters,
  toggleModal,
  toggleSubareas
} from "../../store/settings/actions";

import { Wrapper, FilterWrapper, ContentWrapper, ListWrapper, OverlayWrapper } from './styled';
// import AnalyticsService from "../../services/AnalyticsService";
import Modal from "../App/Modal/Modal";
import FavoriteModal from "../Common/FavoriteModal";
import {getPermissions} from "../../store/auth/selectors";
import {PERMISSION_FILTERS} from "../../store/auth/permissions";
import {showPlansModal} from "../../store/settings/actions";

const mapStateToProps = (state) => ({
  isSubareaOpen: state.settings.status.isSubareaOpen,
  isFilterOpen: state.settings.status.isFilterOpen,
  isMobile: state.settings.isMobile,
  resultsType: state.settings.resultsType,
  config: state.settings.config,
  layout: state.settings.layout,
  language: state.localization.current,
  translations: state.localization.translations,
  subarea: state.settings.subarea,
  companies: state.companies,
  products: state.products,
  casehistories: state.casehistories,
  events: state.events,
  subcompanies: state.subcompanies,
  subcasehistories: state.subcasehistories,
  favorites: state.auth.favorites,
  recents: state.auth.recents,
  filters: state.settings.filters,
  modals: state.settings.modals,
  permissions: getPermissions(state)
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

class List extends Component {

  constructor(props) {
    super(props);
    // console.log("LIST");
    this.props = props;

    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.handleToggleFilters = this.handleToggleFilters.bind(this);
    this.handleCloseFilters = this.handleCloseFilters.bind(this);
    this.handleGetFacets = this.handleGetFacets.bind(this);
    this.handleSetFilters = this.handleSetFilters.bind(this);
    this.handleResetFilters = this.handleResetFilters.bind(this);
    this.handleSubmitFilters = this.handleSubmitFilters.bind(this);
    this.handleResetFacets = this.handleResetFacets.bind(this);
    this.handleSetResultsType = this.handleSetResultsType.bind(this);
    this.handleShowSubareas = this.handleShowSubareas.bind(this);
    this.handleSetLevels = this.handleSetLevels.bind(this);

    this.handleCloseFavoriteModal = this.handleCloseFavoriteModal.bind(this);

  }

  componentDidMount() {

    this.getArea();

  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    const { match } = this.props;
    if (prevProps.match.params.subarea !== match.params.subarea) {
      this.getArea(prevProps);
      // console.log('did update');
    }

  }

  componentWillUnmount() {
    this.handleResetFilters();
  }

  getArea(prevProps) {

    const { dispatch, area } = this.props;
    const { subarea } = this.props.match.params;

    const data = this.props[area];

    // const resetSubarea = data.filtersType !== `${area}-${subarea}`;
    const resetSubarea = true;

    const payload = {
      page: 0,
      subarea,
      timestamp: new Date().getTime(),
      reset: true,
      resetSubarea,
      filters: data.filters
    };

    if (area === 'companies') {
      dispatch(companiesSetList(payload));
      dispatch(companiesSetFiltersSchema());
    } else if (area === 'products') {
      dispatch(productsSetList(payload));
      dispatch(productsSetFiltersSchema(subarea));
    } else if (area === 'casehistories') {
      dispatch(casehistoriesSetList(payload));
      dispatch(casehistoriesSetFiltersSchema())
    } else if (area === 'events') {
      dispatch(eventsSetList(payload));
      dispatch(eventsSetFiltersSchema())
    } else if (area === 'subcompanies') {
      dispatch(subcompaniesSetList(payload));
      dispatch(subcompaniesSetFiltersSchema())
    } else if (area === 'subcasehistories') {
      dispatch(subcasehistoriesSetList(payload));
      dispatch(subcasehistoriesSetFiltersSchema())
    }

    this.handleDetectPageChanges(prevProps);

  }

  getData() {

    const { area } = this.props;
    return this.props[area];

  }

  handleDetectPageChanges(prevProps) {

    const { dispatch, match, area } = this.props;
    const { params } = match;

    if (this.props.language !== params.lang) {
      dispatch(setLocale(params.lang));
    }

    if ((prevProps?.area !== area && area) || (prevProps?.match.params.subarea !== this.props.match.params.subarea)) {
      dispatch(setArea({area, subarea: this.props.match.params.subarea}));
    }

  }

  handleLoadMore(search, reset, updateTimestamp = false) {

    const { dispatch, area, subarea } = this.props;
    const data = this.props[area];

    const payload = {
      reset: reset,
      search: search,
      subarea: subarea,
      page: reset ? 0 : data.page + 1,
      filters: data.filters,
      timestamp: updateTimestamp ? new Date().getTime() : data.timestamp
    }

    if (area === 'companies') {
      dispatch(companiesSetList(payload));
    } else if (area === 'products') {
      dispatch(productsSetList(payload));
    } else if (area === 'casehistories') {
      dispatch(casehistoriesSetList(payload));
    } else if (area === 'events') {
      dispatch(eventsSetList(payload));
    } else if (area === 'subcompanies') {
      dispatch(subcompaniesSetList(payload));
    } else if (area === 'subcasehistories') {
      dispatch(subcasehistoriesSetList(payload));
    }

  }

  handleToggleFilters() {

    const { config } = this.props;

    const needPermission = config?.needPermissionTo && config.needPermissionTo.indexOf('filters') !== -1;
    const hasPermission = !this.props.config.enable_login || !needPermission || (this.props.permissions.indexOf(PERMISSION_FILTERS) !== -1);

    if (!needPermission || (needPermission && hasPermission)) {
      this.props.dispatch(toggleFilters(!this.props.isFilterOpen));
    } else {
      this.props.dispatch(showPlansModal());
    }

  }

  handleCloseFilters() {

    this.props.dispatch(toggleFilters(false));

  }

  handleGetFacets(field, filters, search = '') {

    const { dispatch, area } = this.props;

    if (field) {

      const data = this.getData();

      if (area === 'companies') {

        dispatch(companiesSetFacets({
          field,
          tempFilters:
          filters,
          filters: data ? data.filters : null,
          search
        }));

      } else if(area === 'products') {

        dispatch(productsSetFacets({
          field,
          tempFilters:
          filters,
          filters: data ? data.filters : null,
          search,
        }));

      } else if(area === 'casehistories') {

        dispatch(casehistoriesSetFacets({
          field,
          tempFilters:
          filters,
          filters: data ? data.filters : null,
          search,
        }));

      } else if(area === 'events') {

        dispatch(eventsSetFacets({
          field,
          tempFilters:
          filters,
          filters: data ? data.filters : null,
          search,
        }));

      } else if(area === 'subcompanies') {

        dispatch(subcompaniesSetFacets({
          field,
          tempFilters:
          filters,
          filters: data ? data.filters : null,
          search,
        }));

      } else if(area === 'subcasehistories') {

        dispatch(subcasehistoriesSetFacets({
          field,
          tempFilters:
          filters,
          filters: data ? data.filters : null,
          search,
        }));

      }

    }

  }

  async handleSetFilters({type, field, facet, filters, remove, reset = false}) {

    const { area, subarea, dispatch } = this.props;

    const filtersType = `${area}${subarea ? '-' + subarea : ''}`;

    if (area === 'companies') {

      await dispatch(companiesSetFilters({filtersType, filters, reset: type === undefined}));

      if (type === 'tree' && !remove) {
        await dispatch(companiesSetFiltersTree({field, facet, filters}));
      }

      // if (!isMobile || isMobile && (reset || remove)) {
        this.handleLoadMore('', true);
      // }

    } else if (area === 'products') {

      await dispatch(productsSetFilters({filtersType, filters, reset: type === undefined}));

      if (type === 'tree' && !remove) {
        await dispatch(productsSetFiltersTree({field, facet, filters}));
      }

      // if (!isMobile || isMobile && (reset || remove)) {
        this.handleLoadMore('', true);
      // }

    } else if (area === 'casehistories') {

      await dispatch(casehistoriesSetFilters({filtersType, filters, reset: type === undefined}));

      if (type === 'tree' && !remove) {
        await dispatch(casehistoriesSetFiltersTree({field, facet, filters}));
      }

      // if (!isMobile || isMobile && (reset || remove)) {
        this.handleLoadMore('', true);
      // }

    } else if (area === 'events') {

      await dispatch(eventsSetFilters({filtersType, filters, reset: type === undefined}));

      if (type === 'tree' && !remove) {
        await dispatch(eventsSetFiltersTree({field, facet, filters}));
      }

      // if (!isMobile || isMobile && (reset || remove)) {
        this.handleLoadMore('', true);
      // }

    } else if (area === 'subcompanies') {

      await dispatch(subcompaniesSetFilters({filtersType, filters, reset: type === undefined}));

      if (type === 'tree' && !remove) {
        await dispatch(subcompaniesSetFiltersTree({field, facet, filters}));
      }

      // if (!isMobile || isMobile && (reset || remove)) {
        this.handleLoadMore('', true);
      // }

    } else if (area === 'subcasehistories') {

      await dispatch(subcasehistoriesSetFilters({filtersType, filters, reset: type === undefined}));

      if (type === 'tree' && !remove) {
        await dispatch(subcasehistoriesSetFiltersTree({field, facet, filters}));
      }

      // if (!isMobile || isMobile && (reset || remove)) {
        this.handleLoadMore('', true);
      // }

    }

  }

  handleResetFilters() {

    this.handleSetFilters({reset: true});
    this.props.dispatch(setLevels({
      levels: [],
      levelsDepth: 0
    }));

  }

  handleResetFacets() {

    const { area } = this.props;

    if (area === 'companies') {

      this.props.dispatch(companiesSetFacets({ reset: true }));

    } else if (area === 'products') {

      this.props.dispatch(productsSetFacets({ reset: true }));

    } else if (area === 'casehistories') {

      this.props.dispatch(casehistoriesSetFacets({ reset: true }));

    } else if (area === 'events') {

      this.props.dispatch(eventsSetFacets({ reset: true }));

    } else if (area === 'subcompanies') {

      this.props.dispatch(subcompaniesSetFacets({ reset: true }));

    } else if (area === 'subcasehistories') {

      this.props.dispatch(subcasehistoriesSetFacets({ reset: true }));

    }

  }

  handleSubmitFilters() {

    this.handleLoadMore('', true, true);
    this.handleCloseFilters();

  }

  handleSetResultsType(type) {

    const { dispatch } = this.props;
    dispatch(setResultsType(type));

  }

  handleShowSubareas() {

    const { dispatch } = this.props;
    dispatch(toggleSubareas(true));

  }

  handleSetLevels(filters) {

    const { dispatch } = this.props;
    dispatch(setLevels(filters));

  }

  handleCloseFavoriteModal() {

    const { dispatch } = this.props;
    dispatch(toggleModal('favorite'));

  }

  handleResultItemClick = (e, type) => {

    const { area, config, permissions } = this.props;
    const areaConfig = config.areas[area];

    const needPermission = config?.needPermissionTo && config.needPermissionTo.indexOf(`${area}-detail`) !== -1;
    if (needPermission && config.enable_login && areaConfig.hasOwnProperty('singlePermission') && areaConfig.singlePermission !== '' && permissions.indexOf(areaConfig.singlePermission) === -1) {
      e.preventDefault();
      this.props.dispatch(showPlansModal());
    }

  }

  render() {

    const { area, subarea, analytics, theme, isMobile, config, layout, language, resultsType, isSubareaOpen, filters, isFilterOpen, modals } = this.props;
    let { translations, detailBaseUrl } = this.props;

    detailBaseUrl = replaceStrWithObj(detailBaseUrl, {':lang': language, ':subarea': subarea});

    translations = translations[language];

    let data = this.getData();

    return (
      <Wrapper>
        {analytics ? (<Analytics name={area} listType={resultsType} />) : null}
        <FilterWrapper layout={layout} widthSize={theme.list.width} background={theme.filters.background}>
          <Filters
            layout={layout}
            filtersSchema={this.props[area].filtersSchema}
            filters={filters}
            language={language}
            levelTransitionDuration={theme.list.levelTransition}
            isMobile={isMobile}
            width={theme.list.width}
            area={area}
            subarea={subarea}
            data={data}
            config={config}
            translations={translations}
            isSubareaOpen={isSubareaOpen}
            onCloseFilters={this.handleCloseFilters}
            onResetFilters={this.handleResetFilters}
            onGetFacets={this.handleGetFacets}
            onSetFilters={this.handleSetFilters}
            onSubmitFilters={this.handleSubmitFilters}
            onResetFacets={this.handleResetFacets}
            onShowSubareas={this.handleShowSubareas}
            onSetLevels={this.handleSetLevels}
          />
        </FilterWrapper>
        <ContentWrapper id={'content-wrapper'} layout={layout} widthSize={theme.list.width} background={theme.list.background} color={theme.list.textColor}>
          {/*<TopbarWrapper>
            {isMobile ?
              <MobileTopbar hideListButton={true} />
              :
              <Link to={`/${language}`}>
                <LogoWrapper height={theme.topbar.desktop.height}>
                  <Logo
                    variant={theme.list.logo.variant}
                    width={theme.list.logo.width}
                    height={theme.list.logo.height}
                  />
                </LogoWrapper>
              </Link>
            }
          </TopbarWrapper>*/}
          <ListWrapper>
            <Sidebar
              data={data}
              activeFilters={!isEmpty(data.filters)}
              detailBaseUrl={detailBaseUrl}
              resultsType={resultsType}
              translations={translations}
              isMobile={false}
              config={config}
              language={language}
              area={area}
              subarea={subarea}
              onToggleFilters={this.handleToggleFilters}
              onLoadMore={this.handleLoadMore}
              onSetResultsType={this.handleSetResultsType}
              onResultItemClick={this.handleResultItemClick}
            />
          </ListWrapper>
        </ContentWrapper>
        {isFilterOpen && (<OverlayWrapper onClick={this.handleToggleFilters} widthSize={theme.list.width} />)}
        <Modal show={modals.favorite} onClose={this.handleCloseFavoriteModal}>
          <FavoriteModal translations={translations['UI']} />
        </Modal>
      </Wrapper>
    );
  }

}

export default compose(
  withRouter,
  withTheme,
  connect(mapStateToProps, mapDispatchToProps)
)(List);
