import React, {useEffect, useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {connect, useDispatch} from 'react-redux';
import { useSelector } from 'react-redux'
import styled from 'styled-components';
import {compose} from "redux";
import ReactHtmlParser from 'react-html-parser';
import { withTheme } from './../../../themes';

// import { useParams } from 'react-router-dom';
import { useTheme } from './../../../themes';
import { getTranslations, getLocale } from "./../../../store/localization/selectors";
import {getFavorites, getPermissions} from './../../../store/auth/selectors';
import {
  // getArea,
  getConfig,
  // getIfMobile,
  getLayout,
  // getReady,
  // getStatus,
  // getSubarea
} from './../../../store/settings/selectors';
import {getUser, hasPermissionTo} from "./../../../store/auth/selectors";

// import { DOMAINS } from "./../../../domains";
import {LAYOUT_XS, LAYOUT_SM, LAYOUT_MD} from './../../../settings';

import ContactForm from "./../../../components/Common/ContactForm";

// import SheetTopbar from './../../../components/Common/SheetTopbar';
// import Anchors from "./../../../components/Common/Anchors"
// import LabelledValue from "./../../../components/Common/LabelledValue"
import Container from './../../../components/App/Container';
import Spacer from './../../../components/App/Spacer';
import Chunk from './../../../components/App/Chunk';
// import Cover from './../../../components/App/Cover';
import InfoRow from './../../../components/App/InfoRow';
import Image from './../../../components/App/Image';
import Button from './../../../components/App/Button';
import Modal from './../../../components/App/Modal/Modal';
import Icon from './../../../components/App/Icon';
// import Logo from "./../../../components/App/Logo";
import ReadMore from "./../../../components/App/ReadMore";
import Actionbar from "./../../../components/Common/Actionbar";
import FindUs from "./../../../components/Common/FindUs";

import Result from './../../../components/List/Sidebar/ResultsList/Result';
// import CompanyThumb from './../Companies/CompanyThumb';


// import { requestFile } from './../../../data/network/fileDownloader'
import {setFavorite} from "../../../store/auth/actions";
import {PERMISSION_PRODUCT} from "../../../store/auth/permissions";


import { showPlansModal} from "../../../store/settings/actions";
import {getCompanyActive} from "../../../store/chat/actions";
import {getActiveCompanies} from "../../../store/chat/selectors";


import NewsService from './../../../services/NewsService';
import Post from './../../../components/App/Post';
import Slider from './../../../components/App/Slider';

import {slugify} from "./../../../helpers";

import CompactMenuIcon from './../../../components/App/CompactMenuIcon';
import Meta from "./../../../components/App/Meta";

const Fixed = styled.div`
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 999;
  background: #FFFFFF;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;
`;
// const CoverContainer = styled.div`
//   position: relative;
//   overflow: hidden;
//   margin-bottom: -110px;
//   width: 100%;
// `;
const MainContainer = styled.div`
  margin-top: 3px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;
`;
const MacroRow = styled.div`
  display: flex;
  flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
  flex-wrap: wrap;
  margin-right: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  margin-left: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;

`;
const MacroCol = styled.div`
  padding: 0px ${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  width: ${props => props.size && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.size : 100}%;
`;
// const Row = styled.div`
//   display: flex;
//   flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
//   flex-wrap: wrap;
//   margin-right: -7px;
//   margin-left: -7px;

// `;
// const Col = styled.div`
//   padding: 0px 7px;
//   width: ${props => props.size && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.size : 100}%;
// `;
// const Company = styled.div`
//   color: #000000;
//   font-size: 32px;
//   font-weight: 500;
// `;
const Position = styled.div`
  display: inline-block;
  background: #E4018E;
  color: #fff;
  text-align: center;
  padding: 20px;
  border-radius: 12px;
  font-size: 24px;
`;
// const Brand = styled.div`
//   color: #000000;
//   font-size: 23px;
//   font-weight: 700;
// `;
const Title = styled.h1`
  font-weight: bold;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
// const Designation = styled.div`
//   color: #000000;
//   font-size: 18px;
//   font-weight: 300;
// `;

//
// const Text = styled.div`
//   column-count: ${props => props.cols && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.cols : 1};
//
//   & a:hover{
//     font-weight: bold;
//   }
// `;


// const PackagingHeader = styled.div`
//   display: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "flex" : "none"};
//   flex-direction: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "row" : "column"};
//   margin-bottom: 10px;

//   & > div:nth-child(1){
//     width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 35 : 100}%;
//   }
//   & > div:nth-child(2){
//     width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 15 : 100}%;
//   }
//   & > div:nth-child(3){
//     width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 15 : 100}%;
//   }
//   & > div:nth-child(4){
//     width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 35 : 100}%;
//   }
// `;
// const PackagingHeaderCell = styled.div`
//   flex-grow: 0;
//   flex-shrink: 0;
//   color: #000000;
//   font-size: 11px;
//   font-weight: 600;
//   text-align: ${props => props.last && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? "right" : "left"};
//   text-transform: uppercase;
// `;
// const PackagingValue = styled.div`
//   display: flex;
//   flex-direction: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "row" : "column"};
//   border-bottom: 1px solid #E4018E;

//   & > div:nth-child(1){
//     width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 35 : 100}%;
//   }
//   & > div:nth-child(2){
//     width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 15 : 100}%;
//   }
//   & > div:nth-child(3){
//     width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 15 : 100}%;
//   }
//   & > div:nth-child(4){
//     width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 35 : 100}%;
//   }
// `;
// const PackagingValueCell = styled.div`
//   width: 25%;
//   flex-grow: 0;
//   flex-shrink: 0;
//   padding: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 15 : 10 }px 0px;
//   text-align: ${props => props.last && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? "right" : "left"};
//   color: ${props => props.first ? "#E4018E" : "#606060"};
//   font-weight: ${props => props.first ? "bold" : "normal"};
//   font-size: ${props => props.first ? 16 : 13}px;
// `;
// const Center = styled.div`
//   text-align: center;
// `;


const ContactsModal = styled.div`
  width: 100%;
  max-width: 500px;
  background: #fff;
  padding: 30px;
`;

const ContactsModalHeader = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

const ModalTitle = styled.div`
  color: #E4018E;
  font-size: 22px;
  font-weight: 700;
  text-align: left;
`;
const HallImageCnt = styled.div`
  position: relative;
`;
const HallImage = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`;
// const ListHighlight = styled.div`
//   text-trasform: uppercase;
//   color: #E4018E;
//   font-size: 15px;
//   font-weight: 700;
// `;

// const HallTitle = styled.div`
//   color: #E4018E;
//   font-size: 12px;
//   font-weight: 700;
// `;
// const HallText = styled.div`
//   color: #000000;
//   font-size: 12px;
//   margin-bottom: 10px;

// `;

const CompanyName = styled.div`
  color: #E4018E;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: center;

`;




const AttachmentsValue = styled.div`
  display: flex;
  flex-direction: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "row" : "column"};
  border-bottom: 1px solid #E4018E;
  align-items: center;
`;
const AttachmentsValueCell = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 15 : 10 }px 0px;
  margin-right: 10px;
  text-align: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "left" : "center" };

  &:nth-child(1){
    width: 75%;
  }
  &:nth-child(2){
    width: 25%;
  }
`;
const AttachmentName = styled.div`
  color: #E4018E;
  font-weight: bold;
  font-size: 13px;
`;

const PremiumCta = styled.div`
  text-align: center;
  margin: 30px auto;
  color: #ccc;

  button {
    display: inline-block;
  }
`;

export const CompactMenuIconWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
   width: 46px;
   height: 46px;
   flex-grow: 0;
   flex-shrink: 0;
   margin-left: 7px;
   z-index: 9999;
`;

const mapStateToProps = (state) => ({
  translations: getTranslations(state),
  config: getConfig(state),
  layout: getLayout(state),
  user: getUser(state),
  favorites: getFavorites(state)
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});



function VirCommonSheet(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  // const history = useHistory();
  const canProductPdf = useSelector(hasPermissionTo('productpdf:r'));
  const translations = useSelector(getTranslations);
  const lang = useSelector(getLocale);
  // const config = useSelector(getConfig);
  const layout = useSelector(getLayout);
  // const user = useSelector(getUser);
  const { data, config, favorites } = props;
  const permissions = useSelector(getPermissions);

  // const exhibitorId = data && data.hasOwnProperty('scope') ? data.scope.exhibitorId : null
  // const exhibitorName = data && data.hasOwnProperty('options') ? data.options.exhibitor.name : null;

  const activeCompanies = useSelector(getActiveCompanies);
  // let isChatActive = getProductActiveChat(activeCompanies, data);

  const isPremium = permissions.indexOf('productdetail:r') !== -1;

  useEffect(() => {
    if(data.companyData){
      if(!activeCompanies.hasOwnProperty(data.scope.exhibitorId)) {
        dispatch(getCompanyActive(data.scope.exhibitorId));
      }
    }
  }, [data, activeCompanies, dispatch])

  const [showModal, toggleModal] = useState(null);
  const [banners, setBanners] = useState(null);
  if(!banners){
    NewsService.getList("Prodotti").then(ret  => {
      // console.log(ret);
      setBanners(ret);
    }).catch(err => console.log('There was an error:' + err));
  }

  function memoHandler() {
  }

  function likeHandler(favorites) {

    dispatch(setFavorite({
      area: 'product',
      idElement: data.id,
      isFavorite: favorites && favorites.filter(item => item.itemType === "product" && item.itemId === data.id).length
    }));

  }

  function pdfSheetHandler() {

    if (canProductPdf || !config.enable_login) {
      toggleModal("technical");
    } else {
      dispatch(showPlansModal())
    }

  }

  return data /*&& permissions && permissions.indexOf(PERMISSION_PRODUCT) !== -1*/ ? (
    <>
      <Meta
          title={data ? data.options.name[lang] : null}
          description={data ? (
            data.options.name
          ) : null}
          image={data && data.options.headerImage ? data.options.headerImage.src : null}
      />
      
      <CompactMenuIconWrapper>
        <CompactMenuIcon />
      </CompactMenuIconWrapper>
      
      <Fixed>
        <Container>
          <Actionbar list={[
            {
              name: translations["UI"]["Memo"],
              icon: theme.icons.set.memo,
              onClick: memoHandler,
              show: false,
              isActive: false,
              isDisabled: false
            },{
              name: favorites && favorites.filter(item => item.itemType === "product" && item.itemId === data.id).length > 0 ? translations["UI"]["Liked"] : translations["UI"]["Like"],
              icon: theme.icons.set.favorite,
              onClick: () => likeHandler(favorites),
              show: true,
              isActive: favorites && favorites.filter(item => item.itemType === "product" && item.itemId === data.id).length > 0,
              isDisabled: false
            },{
              name: translations["UI"]["Contact"],
              icon: theme.icons.set.contact,
              onClick: () => toggleModal("contact"),
              show: data.options.exhibitor.contacts && data.options.exhibitor.contacts.filter((item) => item.classification._id === "email").length,
              isActive: false,
              isDisabled: false
            },{
              name: translations["UI"]["Save"],
              icon: theme.icons.set.calendar,
              onClick: null,
              show: false,
              isActive: false,
              isDisabled: false
            }
          ]}/>
        </Container>
      </Fixed>
      <Wrapper>

        <MainContainer>
          <Container>

          <Title>
            <Icon color={"#606060"} name={theme.icons.set.homepage} size={{value: 35, unit: "px"}} />
            {data.options.name[lang]}
          </Title>
          <Spacer size={{value: 12, unit: "px"}} />

          <MacroRow layout={layout}>
            <MacroCol size="66" layout={layout}>
              <Chunk title={translations["sheet-product"]["Presentation"]}>
                { data.options.headerImage ?
                  <>
                    <Image fit={"contain"} width={{value: 100, unit: "%"}} height={{value: 500, unit: "px"}} src={data.options.headerImage.src} alt={data.options.name[lang]} />
                    <Spacer size={{value: 25, unit: "px"}} />
                  </>
                :
                  <>
                    <Image fit={"contain"} width={{value: 100, unit: "%"}} height={{value: 220, unit: "px"}} src={theme.placeholders.product} alt={data.options.name[lang]} />
                    <Spacer size={{value: 25, unit: "px"}} />
                  </>
                }
                {isPremium && (data.TechnicalSheet && (
                  <>
                    <Button
                      variant={"default-o"}
                      display={"inline-block"}
                      onClick={pdfSheetHandler}
                    >
                      {translations["UI"]["Technical sheet"]}
                    </Button>
                  </>
                ))}
              </Chunk>

              {(config.enable_login && !isPremium) && (
                <PremiumCta>
                  <Button onClick={() => dispatch(showPlansModal())} size={"tiny"} variant={"default-o"}>{translations["UI"]["_upgrade-premium-button"]}</Button>
                  <p>{translations["UI"]["_upgrade-premium-text"]}</p>
                </PremiumCta>
              )}

              <Spacer size={{value: 25, unit: "px"}} />

              { (
                isPremium &&
                ((data.options.description && data.options.description[lang]) ||
                (data.options.isNovelty) ||
                (data.options.typology.classification && data.options.typology.classification[lang] ) ||
                (data.options.materialAvailability && data.options.materialAvailability[lang]) ||
                (data.options.blockAverageDimension && data.options.blockAverageDimension[lang]) ||
                (data.options.useCase && data.options.useCase[lang]) ||
                (data.options.impactResistance) ||
                (data.options.frictionWear) ||
                (data.options.weightPerVolumeUnit) ||
                (data.options.useApplications && data.options.useApplications.length > 0) ||
                (data.options.extractionZone.primary && data.options.extractionZone.primary[lang]) ||
                (data.options.extractionZone.secondary && data.options.extractionZone.secondary[lang]) ||
                (data.options.extractionZone.tertiary && data.options.extractionZone.tertiary[lang]))
              ) ?
                <>
                <Chunk title={translations["sheet-product"]["Presentation"]}>
                    {data.options.description && data.options.description[lang] ?
                      <InfoRow
                        label={translations["sheet-product"]["Description"]}
                      >
                        {ReactHtmlParser(data.options.description[lang])}
                      </InfoRow>
                    : ""}
                    {data.options.isNovelty ?
                      <InfoRow
                        label={translations["sheet-product"]["Novelty"]}
                        value={translations["UI"]["Yes"]}
                      />
                    : ""}
                    {data.options.typology.classification && data.options.typology.classification[lang] ?
                      <InfoRow
                        label={translations["sheet-product"]["Category"]}
                        value={data.options.typology && data.options.typology.classification ? (data.options.typology.classification ? data.options.typology.classification[lang] : "") + (data.options.typology.category ? ", " + data.options.typology.category[lang] : "") + (data.options.typology.subCategory ? ", " + data.options.typology.subCategory[lang] : "") : ""}
                      />
                    : ""}
                    { data.options.materialAvailability && data.options.materialAvailability[lang] ?
                      <InfoRow
                        label={translations["sheet-product"]["Material availability"]}
                        value={data.options.materialAvailability[lang]}
                      />
                    : ""}
                    { data.options.blockAverageDimension && data.options.blockAverageDimension[lang] ?
                      <InfoRow
                        label={translations["sheet-product"]["Blocks average dimension"]}
                        value={data.options.blockAverageDimension[lang]}
                      />
                      : ""}
                    { data.options.useCase && data.options.useCase[lang] ?
                      <InfoRow
                        label={translations["sheet-product"]["Use cases"]}
                        value={data.options.useCase[lang]}
                      />
                      : ""}
                    { data.options.impactResistance ?
                      <InfoRow
                        label={translations["sheet-product"]["Impact resistance"]}
                        value={data.options.impactResistance}
                      />
                      : ""}
                    { data.options.frictionWear ?
                      <InfoRow
                        label={translations["sheet-product"]["Friction wear"]}
                        value={data.options.frictionWear}
                      />
                      : ""}
                    { data.options.weightPerVolumeUnit ?
                      <InfoRow
                        label={translations["sheet-product"]["Weight per volume unit"]}
                        value={data.options.weightPerVolumeUnit}
                      />
                      : ""}
                    { data.options.useApplications && data.options.useApplications.length > 0 ?
                      <InfoRow
                        label={translations["sheet-product"]["Use applications"]}
                        value={data.options.useApplications.map((val, i) => (
                          val[lang] ?
                            <span key={val[lang]}>{val[lang]}{i !== data.options.useApplications.length - 1 ? ", " : ""}</span>
                          : null
                        ))}
                      />
                      : ""}
                    { data.options.extractionZone.primary && data.options.extractionZone.primary[lang] ?
                      <InfoRow
                        label={translations["sheet-product"]["Extraction zone"]}
                        value={data.options.extractionZone.primary[lang]}
                      />
                      : ""}
                    { data.options.extractionZone.secondary && data.options.extractionZone.secondary[lang] ?
                      <InfoRow
                        label={translations["sheet-product"]["Second possible extraction zone"]}
                        value={data.options.extractionZone.secondary[lang]}
                      />
                      : ""}
                    { data.options.extractionZone.tertiary && data.options.extractionZone.tertiary[lang] ?
                      <InfoRow
                        label={translations["sheet-product"]["Third possible extraction zone"]}
                        value={data.options.extractionZone.tertiary[lang]}
                      />
                      : ""}
                  </Chunk>
                  <Spacer size={{value: 25, unit: "px"}} />
                </>
              : "" }



              {isPremium && (data.options && ((lang === "it" && data.options.descriptionSheetItalian) || (lang === "en" && data.options.descriptionSheetEnglish))) ?
                <>
                  <Chunk title={translations["sheet-event"]["Attachments"]}>
                    {lang === "it" && data.options.descriptionSheetItalian ?
                      <AttachmentsValue layout={layout}>
                        <AttachmentsValueCell layout={layout}>
                          <AttachmentName>{data.options.descriptionSheetItalian.options.lTitle[lang]}</AttachmentName>
                        </AttachmentsValueCell>
                        <AttachmentsValueCell layout={layout}>
                          <a rel="noopener noreferrer" href={data.options.descriptionSheetItalian.src} target="_blank">
                            <Button display="inline-block" variant={"default-o"}>{translations["UI"]["Download"]}</Button>
                          </a>
                        </AttachmentsValueCell>
                      </AttachmentsValue>
                    : null}
                    {lang === "en" && data.options.descriptionSheetEnglish ?
                      <AttachmentsValue layout={layout}>
                        <AttachmentsValueCell layout={layout}>
                          <AttachmentName>{data.options.descriptionSheetEnglish.options.lTitle[lang]}</AttachmentName>
                        </AttachmentsValueCell>
                        <AttachmentsValueCell layout={layout}>
                          <a rel="noopener noreferrer" href={data.options.descriptionSheetEnglish.src} target="_blank">
                            <Button display="inline-block" variant={"default-o"}>{translations["UI"]["Download"]}</Button>
                          </a>
                        </AttachmentsValueCell>
                      </AttachmentsValue>
                    : null}
                  </Chunk>
                  <Spacer size={{value: 25, unit: "px"}} />
                </>
              : null }


            </MacroCol>
            <MacroCol size="34" layout={layout}>
              {banners ?
                <>
                  <Slider padding={{value: 0, unit: "px"}} size={1} showDots={false} showArrows={false} timing={10000} items={banners.sort(() => 0.5-Math.random()).map(item =>
                    <React.Fragment key={item.titolo_it}>
                      <Post
                        variant={"advertising"}
                        abstract={ReactHtmlParser(item["abstract_"+(lang === "it" ? lang : "en")])}
                        content={item["testo_"+(lang === "it" ? lang : "en")] ? ReactHtmlParser(item["testo_"+(lang === "it" ? lang : "en")]) : null}
                        ctaText={item["cta_"+(lang === "it" ? lang : "en")] ? ReactHtmlParser(item["cta_"+(lang === "it" ? lang : "en")]) : null}
                        ctaLink={item["link_"+(lang === "it" ? lang : "en")] ? ReactHtmlParser(item["link_"+(lang === "it" ? lang : "en")]) : null}
                        image={item.immagine}
                      />
                      <Spacer size={{value: 34, unit: "px"}} />
                    </React.Fragment>
                  )} />
                </>
              : null }

              {data.options.exhibitor ?
                <>
                  <Chunk open={true} collapsible={false}>
                    {data.options.exhibitor.logo ?
                      <center>
                        <Image src={data.options.exhibitor.logo.src} width={{"value": 80, "unit": "%"}} height={{"value": 120, "unit": "px"}} fit={"contain"} alt="logo"/>
                      </center>
                    :
                      <CompanyName>{data.options.exhibitor.name}</CompanyName>
                    }
                    <Spacer size={{value: 20, unit: "px"}}/>
                    <center>
                      <Link to={"/"+lang+"/companies/"+data.scope.exhibitorId+"/"+slugify(data.options.exhibitor.name)}>
                        <Button display="inline-block" variant={"default-o"}>{translations["sheet-product"]["_more-company-info"]}</Button>
                      </Link>
                    </center>
                  </Chunk>

                  <Spacer size={{value: 14, unit: "px"}}/>
                </>
              : null}

              <Spacer size={{value: 14, unit: "px"}}/>

              {data.halls && data.halls.length > 0 && config.enable_halls ?
                <>
                  <FindUs>
                    <HallImageCnt>
                      <Image src={config.ozwol.cdn + "/halls/void.svg"} alt="halls map"/>
                      {data.hallsImages.map((hall, index) =>
                        <HallImage key={"hallimage-"+index}>
                          <Image src={hall} alt="halls map"/>
                        </HallImage>
                      )}

                    </HallImageCnt>
                    {data.hallsString.map((hall, index) =>
                      <Position key={`hall-${hall}`}>{hall}</Position>
                    )}
                  </FindUs>

                  <Spacer size={{value: 14, unit: "px"}}/>
                </>
              : null }

              {data.companyProducts.length > 0 ?
                <>
                <Chunk title={translations["sheet-product"]["Other company products"]} open={true} collapsible={false}>
                  <ReadMore
                    visibleContent={data.companyProducts.slice(0,2).map((item, index) =>{
                      // let subareaSlug = null;
                      // if(data){
                      //   let subareas = config.areas["products"].subareas;
                      //   Object.keys(subareas).forEach(function(index){
                      //     if(subareas[index].key === item.type){
                      //       subareaSlug = index;
                      //     }
                      //   });
                      // }
                      return (
                        <Result
                          key={index}
                          slug={item.slug}
                          item={item}
                          area={"products"}
                          onClick={() => null}
                          url={"/"+lang+"/products/"+item.id}
                        />
                      )
                    })}
                    hiddenContent={data.companyProducts.slice(2).length ? data.companyProducts.slice(2).map((item, index) =>{
                      // let subareaSlug = null;
                      // if(data){
                      //   let subareas = config.areas["products"].subareas;
                      //   Object.keys(subareas).forEach(function(index){
                      //     if(subareas[index].key === item.type){
                      //       subareaSlug = index;
                      //     }
                      //   });
                      // }
                      return (
                        <Result
                          key={index}
                          slug={item.slug}
                          item={item}
                          area={"products"}
                          onClick={() => null}
                          url={"/"+lang+"/products/"+item.id}
                        />
                      )
                    }) : null}
                    buttonMore={<Button size={"tiny"} variant={"default-o"} display={"inline-block"}>{translations["UI"]["Show more"]}</Button>}
                    buttonLess={<Button size={"tiny"} variant={"default-o"} display={"inline-block"}>{translations["UI"]["Show less"]}</Button>}
                  />

                  {config.areas["events"].enable && data.productEvents && data.productEvents.length > 0 ?
                    <>
                      <Chunk title={translations["sheet-company"]["Events"]} collapsible={false}>
                        <ReadMore
                          visibleContent={data.productEvents.slice(0,2).map((item, index) =>(
                            <Result
                              key={index}
                              slug={item.slug}
                              item={item}
                              area={"events"}
                              onClick={() => null}
                              url={"/"+lang+"/events/"+item.type+"/"+item.id}
                            />
                          ))}
                          hiddenContent={data.productEvents.slice(2).length ? data.productEvents.slice(2).map((item, index) =>(
                            <Result
                              key={index}
                              slug={item.slug}
                              item={item}
                              area={"events"}
                              onClick={() => null}
                              url={"/"+lang+"/events/"+item.type+"/"+item.id}
                            />
                          )) : null}
                          buttonMore={<Button size={"tiny"} variant={"default-o"} display={"inline-block"}>{translations["UI"]["Show more"]}</Button>}
                          buttonLess={<Button size={"tiny"} variant={"default-o"} display={"inline-block"}>{translations["UI"]["Show less"]}</Button>}
                        />
                      </Chunk>

                      <Spacer size={{value: 14, unit: "px"}}/>
                    </>
                  : null}

                </Chunk>

                <Spacer size={{value: 14, unit: "px"}}/>
              </>
            : null }

              <Spacer size={{value: 14, unit: "px"}}/>

            </MacroCol>
          </MacroRow>


          <Spacer size={{value: 60, unit: "px"}} />

          {/*
          <Company>{data.companyData.name}</Company>
          <Spacer size={{value: 15, unit: "px"}} />
          <Brand>{data.brand}</Brand>
          <Spacer size={{value: 10, unit: "px"}} />
          <Designation>{data.designation[data.designation.length - 1]}</Designation>
          <Spacer size={{value: 60, unit: "px"}} />
          */}




          </Container>
        </MainContainer>
      </Wrapper>



      <Modal show={showModal} onClose={e => toggleModal(false)}>
        {showModal === "contact" ?
          <>
            <ContactsModal>
              <ContactsModalHeader>
                {/*data.options.exhibitor.logo ?
                  <img src={data.options.exhibitor.logo.src} />
                : null */}
                <p>{translations['sheet-company'][`_intro-contatto-diretto-company`].replace('%s', data.options.exhibitor.name)}</p>
              </ContactsModalHeader>
              <ContactForm lang={lang} config={config} translations={translations} company={data.options.exhibitor.name} reset={!showModal} toEmail={data.options.exhibitor.contacts.filter((item) => item.classification._id === "email")[0]} toName={data.options.exhibitor.name} type={"company"} />
            </ContactsModal>
          </>
        : null }
        {showModal === "technical" ?
          <>
            <ContactsModal>
              <ContactsModalHeader>
                <ModalTitle>{translations["UI"]["Technical sheet download"]}</ModalTitle>
              </ContactsModalHeader>
              {data.TechnicalSheet ?
                data.TechnicalSheet.Languages.sort((a, b) => config.languages.indexOf(a) - config.languages.indexOf(b)).map((item, index) =>(
                  <InfoRow
                    key={index}
                    label={(() => {
                      switch (item) {
                        case "it":
                          return "Italiano";
                        case "en":
                          return "English";
                        case "zh":
                          return "中文";
                        case "ja":
                          return "日本語";
                        case "ru":
                          return "русский";
                        case "de":
                          return "Deutsch";
                        case "fr":
                          return "Français";
                        case "es":
                          return "Español";
                        case "pt":
                          return "Português";
                        case "ko":
                          return "한국의";
                        default:
                          return "Uncoded Language";
                      }
                    })()}
                    value={<a rel="noopener noreferrer" href={(config.chatConfig.hasOwnProperty(window.location.hostname) ? config.chatConfig[window.location.hostname].apiUrl : config.chatConfig.default.apiUrl) + "technicalSheet?id="+data.id+"&language="+item} target="_blank"><Button
                      display={"inline-block"}
                    >
                      {translations["UI"]["Download"]}
                    </Button></a>}
                  />
                ))
              : null }
            </ContactsModal>
          </>
        : null }
      </Modal>

    </>
  ) : (data && permissions && permissions.indexOf(PERMISSION_PRODUCT) === -1 ? <Redirect to={`/${lang}`} /> : null);
}

export default compose(
  withTheme,
  connect(mapStateToProps, mapDispatchToProps)
)(VirCommonSheet);
