import {createSelector} from 'reselect'
import {isArray} from "lodash";

export const getUser = createSelector(
    (state) => state.auth.user,
    user => user
)

export const getUserId = createSelector(
    (state) => state,
    user => user
)

export const getRecents = createSelector(
    (state) => state.auth.recents,
    recents => recents
)

export const getFavorites = createSelector(
    (state) => state.auth.favorites,
    favorites => favorites
)

export const getChatUser = createSelector(
    (state) => state.auth.user,
    (user) => user ? ({id: user.id, firstname: user.firstname, lastname: user.lastname}) : null
)

export const getToken = createSelector(
    (state) => state.auth.user,
    (user) => user ? ({accessToken: user.accessToken, idToken: user.accessToken}) : null
);

export const getPermissions = createSelector(
    (state) => state.auth.permissions,
    permissions => permissions
)

export const hasPermissionTo = (permission) => createSelector(
    getPermissions,
    permissions => {
        return permissions && permissions.indexOf(permission) !== -1
    }
)

export const getRoles = createSelector(
    (state) => state.auth.roles,
    roles => roles
)

export const getHasRole = createSelector(
    getRoles,
    (roles, role) => roles.indexOf(role) !== -1
)

export const getIsPremiumUser = createSelector(
    getRoles,
    (roles) => roles && isArray(roles) && roles.indexOf('userPremium') !== -1
)

export const getCompanyData = createSelector(
    (state) => state.auth,
    auth => auth.companyData
)

export const getLetsfairData = createSelector(
    (state) => state.auth,
    ({user, companyData}) => user && user.id ? ({
        nome: user.firstname,
        cognome: user.lastname,
        email: user.email,
        idUtente: user.id,
        azienda: companyData?.name,
        ruolo: companyData?.activity,
        attivitaSpecifica: companyData?.activity2,
        nazione: companyData?.nation,
        indirizzo: companyData?.address,
        cap: companyData?.zipCode,
        citta: companyData?.city,
        provincia: companyData?.province
    }) : null
)
