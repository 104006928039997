import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import {connect, useDispatch} from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux'
import styled from 'styled-components';
import {compose} from "redux";
import { withTheme } from './../../../themes';
import * as moment from 'moment';

// import { useParams } from 'react-router-dom';
import { useTheme } from './../../../themes';
import { getTranslations, getLocale } from "./../../../store/localization/selectors";
import { getFavorites } from './../../../store/auth/selectors';
import {
  // getArea,
  getConfig,
  // getIfMobile,
  getLayout,
  // getReady,
  // getStatus,
  // getSubarea
} from './../../../store/settings/selectors';
import {getUser} from "./../../../store/auth/selectors";

// import { DOMAINS } from "./../../../domains";
import {LAYOUT_XS, LAYOUT_SM, LAYOUT_MD} from './../../../settings';

import ContactForm from "./../../../components/Common/ContactForm";

// import SheetTopbar from './../../../components/Common/SheetTopbar';
// import Anchors from "./../../../components/Common/Anchors"
// import LabelledValue from "./../../../components/Common/LabelledValue"
import Container from './../../../components/App/Container';
import Spacer from './../../../components/App/Spacer';
import Chunk from './../../../components/App/Chunk';
// import Cover from './../../../components/App/Cover';
import Proportional from './../../../components/App/Proportional';
import Badge from './../../../components/App/Badge';
import InfoRow from './../../../components/App/InfoRow';
import Image from './../../../components/App/Image';
import Button from './../../../components/App/Button';
import Modal from './../../../components/App/Modal/Modal';
import Icon from './../../../components/App/Icon';
// import Logo from "./../../../components/App/Logo";
// import Switch from './../../../components/App/Switch';
import ReadMore from "./../../../components/App/ReadMore";
import Actionbar from "./../../../components/Common/Actionbar";
import FindUs from "./../../../components/Common/FindUs";

import Result from './../../../components/List/Sidebar/ResultsList/Result';
// import CompanyThumb from './../Companies/CompanyThumb';
import ProductThumb from './../Products/ProductThumb';


// import { requestFile } from './../../../data/network/fileDownloader'
import {setFavorite} from "../../../store/auth/actions";

import CompactMenuIcon from './../../../components/App/CompactMenuIcon';

import {slugify} from "./../../../helpers";
import Meta from "./../../../components/App/Meta";

const Fixed = styled.div`
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 999;
  background: #FFFFFF;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;
`;
// const CoverContainer = styled.div`
//   position: relative;
//   overflow: hidden;
//   margin-bottom: -110px;
//   width: 100%;
// `;
const MainContainer = styled.div`
  margin-top: 3px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;
`;
const MacroRow = styled.div`
  display: flex;
  flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
  flex-wrap: wrap;
  margin-right: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  margin-left: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;

`;
const MacroCol = styled.div`
  padding: 0px ${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  width: ${props => props.size && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.size : 100}%;
`;
// const Row = styled.div`
//   display: flex;
//   flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
//   flex-wrap: wrap;
//   margin-right: -7px;
//   margin-left: -7px;

// `;
// const Col = styled.div`
//   padding: 0px 7px;
//   width: ${props => props.size && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.size : 100}%;
// `;
// const Company = styled.div`
//   color: #585858;
//   font-size: 32px;
//   font-weight: 500;
// `;
// const Position = styled.div`
//   display: inline-block;
//   background: #E4018E;
//   color: #fff;
//   text-align: center;
//   padding: 20px;
//   border-radius: 12px;
//   font-size: 24px;
// `;
// const Brand = styled.div`
//   color: #585858;
//   font-size: 23px;
//   font-weight: 700;
// `;
const Title = styled.h1`
  font-weight: bold;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
// const Designation = styled.div`
//   color: #585858;
//   font-size: 18px;
//   font-weight: 300;
// `;

//
// const Text = styled.div`
//   column-count: ${props => props.cols && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.cols : 1};
//
//   & a:hover{
//     font-weight: bold;
//   }
// `;


const HallImageCnt = styled.div`
  position: relative;
`;
// const HallImage = styled.div`
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   width: 100%;
//   height: 100%;
// `;
// const HallTitle = styled.div`
//   color: #E4018E;
//   font-size: 12px;
//   font-weight: 700;
// `;
// const HallText = styled.div`
//   color: #585858;
//   font-size: 12px;
//   margin-bottom: 10px;

// `;

// const Text = styled.div`
//   column-count: ${props => props.cols && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.cols : 1};

//   & a:hover{
//     font-weight: bold;
//   }
// `;


// const SpeakersHeader = styled.div`
//   display: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "flex" : "none"};
//   flex-direction: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "row" : "column"};
//   margin-bottom: 10px;

//   & > div:nth-child(1){
//     width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 35 : 100}%;
//   }
//   & > div:nth-child(2){
//     width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 15 : 100}%;
//   }
//   & > div:nth-child(3){
//     width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 15 : 100}%;
//   }
//   & > div:nth-child(4){
//     width: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 35 : 100}%;
//   }
// `;
// const SpeakersHeaderCell = styled.div`
//   flex-grow: 0;
//   flex-shrink: 0;
//   color: #000000;
//   font-size: 11px;
//   font-weight: 600;
//   text-align: ${props => props.last && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? "right" : "left"};
//   text-transform: uppercase;
// `;
const SpeakersValue = styled.div`
  display: flex;
  flex-direction: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "row" : "column"};
  border-bottom: 1px solid #E4018E;
  align-items: center;
`;
const SpeakersValueCell = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 15 : 10 }px 0px;
  margin-right: 10px;
  text-align: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "left" : "center" };

  &:nth-child(1){
    width: 15%;
  }
  &:nth-child(2){
    width: 30%;
  }
  &:nth-child(3){
    width: 40%;
  }
  &:nth-child(4){
    width: 15%;
  }
`;

const SpeakerAvatar = styled.img`
  border: 1px solid #E4018E;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
`;
const SpeakerAvatarPlaceholder = styled.img`
  border: 1px solid #E4018E;
  width: 100%;
  height: 100%;
  background-color: #dedede;
  border-radius: 100%;
`;
const SpeakerName = styled.div`
  color: #E4018E;
  font-weight: bold;
  font-size: 16px;
`;
const SpeakerRole = styled.div`
  color: #606060;
  font-weight: normal;
  font-size: 13px;
`;
const SpeakerDescription = styled.div`
  color: #606060;
  font-weight: normal;
  font-size: 14px;
`;



const AttachmentsValue = styled.div`
  display: flex;
  flex-direction: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "row" : "column"};
  border-bottom: 1px solid #E4018E;
  align-items: center;
`;
const AttachmentsValueCell = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 15 : 10 }px 0px;
  margin-right: 10px;
  text-align: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "left" : "center" };

  &:nth-child(1){
    width: 55%;
  }
  &:nth-child(2){
    width: 20%;
  }
  &:nth-child(3){
    width: 25%;
  }
`;
const AttachmentName = styled.div`
  color: #E4018E;
  font-weight: bold;
  font-size: 13px;
`;

const ScheduleHeader = styled.div`
  display: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "flex" : "none"};
  flex-direction: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "row" : "column"};
  margin-bottom: 10px;
`;
const ScheduleHeaderCell = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  color: #606060;
  font-size: 13px;

  &:nth-child(1){
    width: 74%;
  }
  &:nth-child(2){
    width: 26%;
  }
`;
const ScheduleRow = styled.div`
  border-bottom: 1px solid #dedede;
`;
const ScheduleValue = styled.div`
  display: flex;
  flex-direction: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "row" : "column"};
  border-top: 1px solid #E4018E;
`;
const ScheduleValueCell = styled.div`
  width: 25%;
  flex-grow: 0;
  flex-shrink: 0;
  padding: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? 15 : 10 }px 0px;
  text-align: ${props => props.last && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? "right" : "left"};

  color: #E4018E;
  font-weight: 700;


  &:nth-child(1){
    width: 74%;
  }
  &:nth-child(2){
    width: 26%;
  }
`;

const Joinbar = styled.div`
  flex-direction: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "row" : "column"};
  justify-content: space-between;
  padding-top:20px;
  display: ${props => props.isOpen ? "flex" : "none"};
`;
const JoinbarText = styled.div`
  text-align: left;
  color: #E4018E;
  font-weight: 700;
  text-transform: uppercase;
  padding-top: 10px;
`;
const JoinbarAddress = styled.div`
  text-align: left;
  padding-bottom: 10px;
  font-size: 14px;
`;
const JoinbarCta = styled.div`

`;
const JoinbarActions = styled.div`
 display: flex;
 justify-content: space-between;
`;
const JoinbarCopy = styled.div`
  font-size: 11px;
  letter-spacing: 0.18px;
  color: #606060;
  text-decoration: underline;
  text-align: left;
  margin: 10px 0px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
`;
const JoinbarSave = styled.div`
  font-size: 11px;
  letter-spacing: 0.18px;
  color: #606060;
  text-decoration: underline;
  text-align: right;
  margin: 10px 0px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
`;
const Badges = styled.div`
  display: flex;
  position: relative;
  top: -20px;
  flex-wrap: wrap;

  & > * {
    margin-right: 5px;
  }
`;
const BadgeWrapper = styled.div`
  margin-bottom: 5px;
`;



const ContactsModal = styled.div`
  width: 100%;
  max-width: 500px;
  background: #fff;
  padding: 30px;
`;

const ContactsModalHeader = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;
// const ModalTitle = styled.div`
//   color: #E4018E;
//   font-size: 22px;
//   font-weight: 700;
//   text-align: left;
// `;


const Padding = styled.div`
  padding: ${props => props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM ? "20px" : "15px"};
`;

const CompanyName = styled.div`
  color: #E4018E;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: center;

`;

export const CompactMenuIconWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
   width: 46px;
   height: 46px;
   flex-grow: 0;
   flex-shrink: 0;
   margin-left: 7px;
   z-index: 9999;
`;

const mapStateToProps = (state) => ({
  translations: getTranslations(state),
  config: getConfig(state),
  layout: getLayout(state),
  user: getUser(state),
  favorites: getFavorites(state)
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

function VirCommonSheet(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const translations = useSelector(getTranslations);
  const lang = useSelector(getLocale);
  // const config = useSelector(getConfig);
  const layout = useSelector(getLayout);
  // const user = useSelector(getUser);
  const { data, config, favorites } = props;

// console.log(data);

  function memoHandler() {
  }

  function likeHandler(favorites) {

    dispatch(setFavorite({
      area: 'Event',
      idElement: data.slug,
      isFavorite: favorites && favorites.filter(item => item.itemType === "Event" && item.objectID === data.slug).length > 0
    }));

  }

  const [showModal, toggleModal] = useState(null);

  const areOpen = {};
  // const [areOpen, setAreOpen] = useState({}); //prendere stato precedente da api?

  const downloadIcs = ( (title, content)  => {
    let filename = title+".ics";
    var blob = new Blob([content], {type: 'text/ical'});
    if(window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
    }else{
      var elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  });

  // const setOpen = ( index  => {
  //   // console.log("setOpen " + index);
  //   let arr = JSON.parse(JSON.stringify(areOpen));
  //   arr[index] = true;
  //   setAreOpen(arr);
  //   // console.log(areOpen[0]);
  // });

  // const setClose = ( index  => {
  //   // console.log("setClose " + index);
  //   let arr = JSON.parse(JSON.stringify(areOpen));
  //   arr[index] = false;
  //   setAreOpen(arr);
  //   // console.log(areOpen[0]);
  // });


  return data ? (
    <>
      <Meta
          title={data ? data.options.title[lang] : null}
          description={data ? (
            data.options.title[lang]
          ) : null}
          image={data && data.options.headerImage ? data.options.headerImage.src : null}
      />
      {layout === LAYOUT_XS || layout === LAYOUT_SM ? 
        <CompactMenuIconWrapper>
          <CompactMenuIcon />
        </CompactMenuIconWrapper>
      : null }
      <Fixed>
        <Container>
          <Actionbar list={[{
            name: translations["UI"]["Memo"],
            icon: theme.icons.set.memo,
            onClick: memoHandler,
            show: false,
            isActive: false,
            isDisabled: false
          },{
            name: favorites && favorites.filter(item => item.itemType === "Event" && item.objectID === data.slug).length > 0 ? translations["UI"]["Liked"] : translations["UI"]["Like"],
            icon: theme.icons.set.favorite,
            onClick: () => likeHandler(favorites),
            show: true,
            isActive: favorites && favorites.filter(item => item.itemType === "Event" && item.objectID === data.slug).length > 0,
            isDisabled: false
          },{
            name: translations["UI"]["Contact"],
            icon: theme.icons.set.contact,
            onClick: () => toggleModal("contact"),
            show: data.companyData.options.contacts && data.companyData.options.contacts.filter((item) => item.classification._id === "email").length,
            isActive: false,
            isDisabled: false
          },{
            name: translations["UI"]["Save"],
            icon: theme.icons.set.calendar,
            onClick: null,
            show: false,
            isActive: false,
            isDisabled: false
          }
        ]}/>
        </Container>
      </Fixed>
      <Wrapper>

        <MainContainer>
          <Container>

          <Title>
            <Icon color={"#606060"} name={theme.icons.set.homepage} size={{value: 35, unit: "px"}} />
            {data.options.title[lang]}
          </Title>
          <Spacer size={{value: 12, unit: "px"}} />

          <MacroRow layout={layout}>
            <MacroCol size="66" layout={layout}>

              <Chunk open={true} collapsible={false} noPadding={true} variant="event">
                { data.options.headerImage ?
                  <>
                    <Image fit={"cover"} src={data.options.headerImage.src} alt={data.options.headerImage.options.lTitle[lang]} />
                    <Spacer size={{value: 10, unit: "px"}} />
                  </>
                : <br/> }
                <Padding>
                  {/*data.attendance*/}
                  <Badges>
                    {data.options.eventMacroTypeId ?
                      <BadgeWrapper><Badge variant="default">{data.options.eventMacroTypeId[lang]}</Badge></BadgeWrapper>
                    : null}
                    {data.options.eventTypeId ?
                      <BadgeWrapper><Badge variant="default"><Icon color={"#606060"} name={"calendar-1"} size={{value: 18, unit: "px"}} />&nbsp;{data.options.eventTypeId[lang]}</Badge></BadgeWrapper>
                    : null}
                    {data.options.eventAttendanceId ?
                      <BadgeWrapper><Badge variant="default"><Icon color={"#606060"} name={"ticket-1"} size={{value: 18, unit: "px"}} />&nbsp;{data.options.eventAttendanceId[lang]}</Badge></BadgeWrapper>
                    : null}
                    {data.options.eventAudienceTypeIds ?
                      <BadgeWrapper><Badge variant={"event"}><Icon color={"#E4018E"} name={"users-1"} size={{value: 18, unit: "px"}} />&nbsp;{data.options.eventAudienceTypeIds.map(item => item[lang]).join(", ")}</Badge></BadgeWrapper>
                    : null}
                  </Badges>

                  <InfoRow
                    label={data.options.title[lang]}
                    value={null}
                    variant="event"
                  >
                    {ReactHtmlParser(data.options.description[lang])}
                  </InfoRow>
                  {data.options.countryIds.length ?
                    <InfoRow
                      label={translations["sheet-event"]["Reference countries or markets"]}
                      value={null}
                      variant="event"
                    >
                    {data.options.countryIds.map(val => val[lang]).join(", ")}
                  </InfoRow>
                : null}
                {data.options.contactInformations.length ?
                  <InfoRow
                    label={translations["sheet-event"]["Contacts"]}
                    value={null}
                    variant="event"
                  >
                    {data.options.contactInformations.map((val, index) =>
                      <React.Fragment key={index}>
                        {(() => {
                          switch (val.classification._id) {
                            case "web":
                              return (
                                <>{val.classification[lang] + " " + val.subtype[lang]}: <a rel="noopener noreferrer" href={val.value} target="_blank">{val.value}</a><br/></>
                              );
                            case "phone":
                              return (
                                <>{val.classification[lang] + " " + val.subtype[lang]}: <a rel="noopener noreferrer" href={"tel:"+val.value} target="_blank">{val.value}</a><br/></>
                              );
                            case "email":
                              return (
                                <>{val.classification[lang] + " " + val.subtype[lang]}: <a rel="noopener noreferrer" href={"mailto:"+val.value} target="_blank">{val.value}</a><br/></>
                              );
                            default:
                              return null;
                          }
                        })()}
                      </React.Fragment>
                    )}
                  </InfoRow>
                : null }




                  <Spacer size={{value: 25, unit: "px"}} />

                  <ScheduleHeader layout={layout}>
                    <ScheduleHeaderCell layout={layout}>{translations["sheet-event"]["Day"]}</ScheduleHeaderCell>
                    {/*<ScheduleHeaderCell layout={layout}>{translations["sheet-event"]["Time"]}</ScheduleHeaderCell>*/}
                    <ScheduleHeaderCell layout={layout}>{translations["sheet-event"]["Languages"]}</ScheduleHeaderCell>
                    {/*<ScheduleHeaderCell layout={layout}>{translations["sheet-event"]["Where"]}</ScheduleHeaderCell>
                    <ScheduleHeaderCell layout={layout}>{translations["sheet-event"]["Join"]}</ScheduleHeaderCell>*/}
                  </ScheduleHeader>
                  {data.options.plannings.map((val, index) =>
                    <ScheduleRow key={"schedule-"+index}>
                      <ScheduleValue layout={layout}>
                        <ScheduleValueCell layout={layout}>
                          {(moment(val.dateRange.start)).format('D MMMM YYYY HH:mm')} {val.timezone}
                          {val.dateRange.start !== val.dateRange.end ?
                            <>
                              <br/>
                              {(moment(val.dateRange.end)).format('D MMMM YYYY HH:mm')} {val.timezone}
                            </>
                           : null}
                        </ScheduleValueCell>
                        {/*<ScheduleValueCell layout={layout}>{(moment(val.dateRange.start)).format('HH:mm')} - {(moment(val.dateRange.end)).format('HH:mm')} {val.timezone}</ScheduleValueCell>*/}
                        <ScheduleValueCell layout={layout}>{data.options.localeIds.map((item) => item[lang]).join(", ")}</ScheduleValueCell>
                        {/*<ScheduleValueCell layout={layout}>{ReactHtmlParser(
                          !val.phisical ?
                            "<a href=\""+val.virtualRoom.url+"\" target=\"_BLANK\">"+val.virtualRoom.platform+"</a>"
                          :
                            val.location.description[lang]
                        )}</ScheduleValueCell>
                        }<ScheduleValueCell layout={layout}>
                        {/ * onClick={() => setIsOpen(collapsible && !isOpen)} * /}
                        <Switch
                          checked={areOpen[index] ? areOpen[index] : false}
                          callbackOn={() => setOpen(index)}
                          callbackOff={() => setClose(index)}
                          labelOn={translations["UI"]["Yes"]}
                          labelOff={translations["UI"]["No"]}
                          colorBackgroundOn={"#E4018E"}
                          colorBackgroundOff={"#dedede"}
                          colorTextOn={"#ffffff"}
                          colorTextOn={"#ffffff"}
                        />
                        </ScheduleValueCell>*/}
                      </ScheduleValue>

                      <Joinbar isOpen={areOpen[index] ? areOpen[index] : false}>
                        { !val.phisical ?
                          <JoinbarText>
                            {translations["sheet-event"]["_join-text"]}
                          </JoinbarText>
                        :
                        <JoinbarAddress>{
                          val.phisical && val.location ?
                            <>
                              {val.location.address}<br/>
                              {val.location.city} {val.location.zip}<br/>
                              {val.location.country}
                            </>
                          : null
                        }</JoinbarAddress>
                        }
                        <JoinbarCta>
                          { !val.phisical ?
                            <center>
                              <a rel="noopener noreferrer" href={val.virtualRoom.url} target="_blank">
                                <Button display="inline-block" variant="event">{translations["sheet-event"]["_join-cta"]}</Button>
                              </a>
                            </center>
                          : null }
                          <JoinbarActions>
                            { !val.phisical ?
                              <JoinbarCopy
                                onClick={(e) => {
                                  navigator.clipboard.writeText(val.virtualRoom.url);
                                  let elem = e.currentTarget;
                                  elem.style.opacity = 0.1;
                                  setTimeout(function(){
                                    elem.style.opacity = 1;
                                  }, 200);
                                  // console.log(e.currentTarget);
                                }}
                              >
                                {translations["sheet-event"]["_join-copy"]}
                              </JoinbarCopy>
                            : null }

                            <JoinbarSave>
                              {data.ics ?
                                <>
                                  <span onClick={() => downloadIcs("title",data.ics)}>
                                    {translations["sheet-event"]["_join-save"]}
                                  </span>
                                  <br />
                                </>
                              : null}
                              <a rel="noopener noreferrer" target="_blank" href={
                                "https://www.google.com/calendar/render?action=TEMPLATE"+
                                "&text="+(data.options.title[lang] ? escape(data.options.title[lang]) : "")+
                                "&dates="+(new Date(val.dateRange.start)).toISOString().replace(/-|:|\.\d\d\d/g,"")+"/"+(new Date(val.dateRange.end)).toISOString().replace(/-|:|\.\d\d\d/g,"")+
                                /*"&ctz="+"CET"+*/
                                "&details="+(data.options.description[lang] ? escape(data.options.description[lang]) : "")+
                                "&location="+(!val.phisical ?
                                  (val.virtualRoom.url ? escape(val.virtualRoom.url) : "")
                                :
                                  (val.phisical && val.location ?
                                    (val.location.address ? escape(val.location.address)+",+" : "")+
                                    (val.location.city ? escape(val.location.city)+",+" : "")+
                                    (val.location.zip ? escape(val.location.zip)+",+" : "")+
                                    (val.location.countryId ? escape(val.location.countryId[lang]) : "")
                                  : "")
                                )+
                                "&sf=true&output=xml"
                              }>
                                {translations["sheet-event"]["_join-save-googlecalendar"]}
                              </a>
                              <br />
                              <a rel="noopener noreferrer" target="_blank" href={
                                "https://outlook.office.com/calendar/0/deeplink/compose"+
                                "?subject="+(data.options.title[lang] ? escape(data.options.title[lang]) : "")+
                                "&startdt="+(new Date(val.dateRange.start)).toISOString().replace(/-|:|\.\d\d\d/g,"")+  //2020-10-07T16:00:00Z
                                "&enddt="+(new Date(val.dateRange.start)).toISOString().replace(/-|:|\.\d\d\d/g,"")+ //2020-10-07T17:00:00Z
                                /*"&ctz="+"CET"+*/
                                "&body="+(data.options.description[lang] ? escape(data.options.description[lang]) : "")+
                                "&location="+(!val.phisical ?
                                  (val.virtualRoom.url ? escape(val.virtualRoom.url) : "")
                                :
                                  (val.phisical && val.location ?
                                    (val.location.address ? escape(val.location.address)+",+" : "")+
                                    (val.location.city ? escape(val.location.city)+",+" : "")+
                                    (val.location.zip ? escape(val.location.zip)+",+" : "")+
                                    (val.location.country ? escape(val.location.country) : "")
                                  : "")
                                )
                              }>
                                {translations["sheet-event"]["_join-save-microsoftoutlook"]}
                              </a>
                            </JoinbarSave>
                          </JoinbarActions>
                        </JoinbarCta>
                      </Joinbar>
                    </ScheduleRow>
                  )}
                </Padding>
              </Chunk>
              <Spacer size={{value: 25, unit: "px"}} />

              { data.eventProducts && data.eventProducts.length > 0 ?
                data.eventProducts.map((item, index) =>(
                  <React.Fragment key={index}>
                    <Chunk collapsible={false}>
                      <ProductThumb
                        key={index}
                        item={item}
                      />
                    </Chunk>
                    <Spacer size={{value: 14, unit: "px"}}/>
                  </React.Fragment>
                ))
              : "" }

              { data.options.speakerIds && data.options.speakerIds.length > 0 ?
                <>
                  <Chunk title={translations["sheet-event"]["Speakers"]} variant="event">
                    {data.options.speakerIds.map((val, index) =>
                      <SpeakersValue key={"speakers-"+index} layout={layout}>
                        <SpeakersValueCell layout={layout}>
                          <Proportional ratio="1">
                            {/*val.mediaId && val.mediaId[0].value && val.media[0].value[0].url*/ false ?
                              <SpeakerAvatar src={config.ozwol.cdn + "/event_thumb/" + val.media[0].value[0].url}/>
                            :
                              <SpeakerAvatarPlaceholder />
                            }
                          </Proportional>
                        </SpeakersValueCell>
                        <SpeakersValueCell layout={layout}>
                          <SpeakerName>{val.name} {val.surname}</SpeakerName>
                          <SpeakerRole>{val.qualification[lang]}</SpeakerRole>
                        </SpeakersValueCell>
                        <SpeakersValueCell layout={layout}>
                          <SpeakerDescription>{val.description[lang]}</SpeakerDescription>
                        </SpeakersValueCell>
                        {/*<SpeakersValueCell layout={layout}>Linkedin</SpeakersValueCell>*/}
                      </SpeakersValue>
                    )}
                  </Chunk>
                  <Spacer size={{value: 25, unit: "px"}} />
                </>
              : "" }

              {data.options.attachments && data.options.attachments.length > 0 ?
                <>
                  <Chunk title={translations["sheet-event"]["Attachments"]} variant="event">
                    {data.options.attachments.map((val, index) =>
                      <AttachmentsValue key={"attachments-"+index} layout={layout}>
                        <AttachmentsValueCell layout={layout}>
                          <AttachmentName>{val.options.lTitle[lang]}</AttachmentName>
                        </AttachmentsValueCell>
                        <AttachmentsValueCell layout={layout}>
                          {val.mime}
                        </AttachmentsValueCell>
                        <AttachmentsValueCell layout={layout}>
                          <a rel="noopener noreferrer" href={val.src} target="_blank">
                            <Button display="inline-block" variant={"event-o"}>{translations["UI"]["Download"]}</Button>
                          </a>
                        </AttachmentsValueCell>
                      </AttachmentsValue>
                    )}
                  </Chunk>
                  <Spacer size={{value: 25, unit: "px"}} />
                </>
              : null }




            </MacroCol>
            <MacroCol size="34" layout={layout}>
              {!data.indexData.isInstitutionalEvent ?
                <>
                  <Chunk open={true} collapsible={false}>
                    {data.companyData.options.logo ?
                      <center>
                        <Image src={data.companyData.options.logo.src} width={{"value": 80, "unit": "%"}} height={{"value": 120, "unit": "px"}} fit={"contain"} alt="logo"/>
                      </center>
                    :
                      <CompanyName>{data.companyData.options.name}</CompanyName>
                    }
                    <Spacer size={{value: 20, unit: "px"}}/>
                    <center>
                      <Link to={"/"+lang+"/companies/"+data.companyData.id+"/"+slugify(data.companyData.options.name)}>
                        <Button display="inline-block" variant={"default-o"}>{translations["sheet-event"]["_more-company-info"]}</Button>
                      </Link>
                    </center>
                  </Chunk>

                  {false && data.halls.length > 0 && config.enable_halls ?
                    <>
                      <FindUs>
                        <HallImageCnt>
                          <Image src={config.ozwol.cdn + "/halls/void.svg"} alt="halls map"/>
                          {/*data.hallsImages.map((hall, index) =>
                            <HallImage key={"hallimage-"+index}>
                              <Image src={hall} alt="halls map"/>
                            </HallImage>
                          )*/}

                        </HallImageCnt>
                        {/*data.hallsString.map((hall, index) =>
                          <Position key={`hall-${hall}`}>{hall}</Position>
                        )*/}
                      </FindUs>

                      <Spacer size={{value: 14, unit: "px"}}/>
                    </>
                  : null }

                  <Chunk title={["sheet-event"]["Other company events"]} open={true} collapsible={false}>
                    <ReadMore
                      visibleContent={data.companyEvents.slice(0,2).map((item, index) =>(
                        <Result
                          key={index}
                          slug={item.slug}
                          item={item}
                          url={"/"+lang+"/events/"+item.type+"/"+item.id}
                        />
                      ))}
                      hiddenContent={data.companyEvents.slice(2).length ? data.companyEvents.slice(2).map((item, index) =>(
                        <Result
                          key={index}
                          slug={item.slug}
                          item={item}
                          url={"/"+lang+"/events/"+item.type+"/"+item.id}
                        />
                      )) : null}
                      buttonMore={<Button size={"tiny"} variant={"default-o"} display={"inline-block"}>{translations["UI"]["Show more"]}</Button>}
                      buttonLess={<Button size={"tiny"} variant={"default-o"} display={"inline-block"}>{translations["UI"]["Show less"]}</Button>}
                    />

                  </Chunk>

                  <Spacer size={{value: 14, unit: "px"}}/>
                </>
              : null }
            </MacroCol>
          </MacroRow>


          <Spacer size={{value: 60, unit: "px"}} />

          {/*
          <Company>{data.companyData.name}</Company>
          <Spacer size={{value: 15, unit: "px"}} />
          <Brand>{data.brand}</Brand>
          <Spacer size={{value: 10, unit: "px"}} />
          <Designation>{data.designation[data.designation.length - 1]}</Designation>
          <Spacer size={{value: 60, unit: "px"}} />
          */}




          </Container>
        </MainContainer>
      </Wrapper>



      <Modal show={showModal} onClose={e => toggleModal(false)}>
        {showModal === "contact" ?
          <>
            <ContactsModal>
              <ContactsModalHeader>
                {/*<Image src={data.companyData.options.logo.src} width={{"value": 200, "unit": "px"}}/>*/}
                <p>{translations['sheet-company'][`_intro-contatto-diretto-company`].replace('%s', data.companyData.options.name)}</p>
              </ContactsModalHeader>
              <ContactForm lang={lang} config={config} translations={translations} company={data.companyData.options.name} reset={!showModal} toEmail={data.companyData.options.contacts.filter((item) => item.classification._id === "email").map((item) => item.value)[0]} toName={data.companyData.name} type={"company"} />
            </ContactsModal>
          </>
        : null }
      </Modal>

    </>
  ) : <></>;
}

export default compose(
  withTheme,
  connect(mapStateToProps, mapDispatchToProps)
)(VirCommonSheet);
