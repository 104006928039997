import {forEach, replace} from 'lodash';
import * as moment from 'moment';
import OzwolImageService from "./services/OzwolImageService";

export const slugify = (name, id = null) => {
  name = name.replace(/^\s+|\s+$/g, ''); // trim
  name = name.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to   = "aaaaeeeeiiiioooouuuunc------";
  for (var i=0, l=from.length ; i<l ; i++) {
      name = name.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  name = name.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

  let slug;
  if(id){
    slug = name + "-" + id;
  }else{
    slug = name;
  }
  return slug;
}

export const replaceStrWithObj = (str, obj) => {

  forEach(obj, function (value, key) {
    str = replace(str, `${key}`, value);
  });
  return str;

}

export const sendMail = ({fromName, toName, fromEmail, toEmail, message, reCaptcha, type = 'default', lang = 'it', config, translations}) => {
  let htmlFrom = `
    #TEXT-PREBODY#
    <br><br>
    #TEXT-BODY-TO#: #NAME-TO#
    <br><br>
    #MESSAGE#
  `;
  // <br><br>
  // #TEXT-POSTBODY#
  // <br><br><hr><br><img src="https://cdn.ozwol.cloud/47S3WM8K6G/logo.svg" width="100" alt="vinitaly"/><br>
  let htmlTo = `
    #TEXT-PREBODY#
    <br><br>
    #TEXT-BODY-FROM#: #NAME-FROM#
    <br><br>
    #TEXT-BODY-FROMEMAIL#: #EMAIL-FROM#
    <br><br>
    #MESSAGE#
  `;
  // <br><br>
  // #TEXT-POSTBODY#
  // <br><br><hr><br><img src="https://cdn.ozwol.cloud/47S3WM8K6G/logo.svg" width="100" alt="vinitaly"/><br>

  /*from*/
  htmlFrom = htmlFrom.replace("#TEXT-PREBODY#", translations["email"]["_mail-prebody-" + type + "-from"]);
  htmlFrom = htmlFrom.replace("#TEXT-POSTBODY#", translations["email"]["_mail-postbody-" + type + "-from"]);
  htmlFrom = htmlFrom.replace("#TEXT-BODY-TO#", translations["email"]["_mail-body-to"]);
  htmlFrom = htmlFrom.replace("#NAME-TO#", toName);
  htmlFrom = htmlFrom.replace("#MESSAGE#", message);

  /*to*/
  htmlTo = htmlTo.replace("#TEXT-PREBODY#", translations["email"]["_mail-prebody-" + type + "-to"]);
  htmlTo = htmlTo.replace("#TEXT-BODY-FROM#", translations["email"]["_mail-body-from"]);
  htmlTo = htmlTo.replace("#TEXT-BODY-FROMEMAIL#", translations["email"]["_mail-body-fromemail"]);
  htmlTo = htmlTo.replace("#TEXT-POSTBODY#", translations["email"]["_mail-postbody-" + type + "-to"]);
  htmlTo = htmlTo.replace("#NAME-FROM#", fromName);
  htmlTo = htmlTo.replace("#EMAIL-FROM#", escape(fromEmail));
  htmlTo = htmlTo.replace("#MESSAGE#", message);

  const params = {
    emailFrom: escape(fromEmail),
    emailTo: escape(toEmail),
    subjectFrom: translations["email"]["_mail-subject-" + type + "-from"].replace('%s', toName),
    subjectTo: translations["email"]["_mail-subject-" + type + "-to"].replace('%s', fromName),
    bodyFrom: htmlFrom,
    bodyTo: htmlTo,
    'g-recaptcha-response': reCaptcha,
    email: escape(config.sendmail.email),
    // emailBcc: escape(config.sendmail.bcc)
  };

  const paramsString = Object.keys(params).map(paramKey => `${paramKey}=${params[paramKey]}`).join('&');

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", config.sendmail.endpoint);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.onload = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        resolve(xhr.response);
      } else {
        reject(xhr.statusText);
      }
    }
    xhr.onerror = () => reject(xhr.StatusText);
    xhr.send(paramsString);
  })
}

export const sortArrayNumbers = (array, order = 'asc') => array.sort((a, b) => order === 'asc' ? (a - b) : (b - a));

const easeInOutQuad = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) return c / 2 * t * t + b;
  t--;
  return -c / 2 * (t * (t - 2) - 1) + b;
};

export const scrollTo = (element, to, duration) => {

  if (!element) return;

  var start = element.scrollTop,
    change = to - start,
    currentTime = 0,
    increment = 20;

  var animateScroll = function () {
    currentTime += increment;
    var val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollTop = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
};

export const stripTags = string => string.replace(/(<([^>]+)>)/ig, "");

export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const chatReadableDate = (timestamp, alwaysShowDate = false, translations = {
  yesterday: 'Ieri',
}, locale = 'it') => {
  const today = moment();
  const compare = moment.unix(parseInt(timestamp));
  const daysDiff = today.diff(compare, 'days');
  const hour = compare.format('HH:mm');
  if (today.day() === compare.day()) {
    return (alwaysShowDate ? '' : '') + hour;
  } else if (daysDiff === 0 && today.day() !== compare.day()) {
    return `${translations.yesterday} ${hour}`;
  } else if (daysDiff > 1 && daysDiff <= 7) {
    return `${compare.format('dddd', {locale})} ${hour}`;
  }
  return `${compare.format('D/M/YYYY', {locale})} ${hour}`;
};

export const getDateFormat = (timestamp) => {
  const compare = moment.unix(parseInt(timestamp));
  const hour = compare.format('HH:mm');
  return `${compare.format('DD/MM/YYYY')} ${hour}`;
};

export const getTranslation = (translations, segments) => {
  let translation = {...translations};
  try {
    translation = segments.reduce((acc, item) => acc[item], translations)
  } catch (e) {
    return `# ${segments.join(" > ")}`
  }
  if (!translation) return `# ${segments.join(" > ")}`;
  return translation

}

export const getQueryStringFromObject = (object) => {

  return Object.keys(object).reduce((acc, key) => [...acc, `${key}=${object[key]}`], []).join('&')

}

export const getChildFilters = (obj, key, keys = []) => !obj.hasOwnProperty('properties') ? [...keys, key] : Object.keys(obj.properties).reduce((acc, key2) => [...acc, ...getChildFilters(obj.properties[key2], key2, [])], [key])

export const getProductActiveChat = (activeCompanies, data) => {
  if (!activeCompanies || !data) return false;
  const exhibitorId = data.scope.exhibitorId;
  return activeCompanies && exhibitorId && activeCompanies.hasOwnProperty(exhibitorId) && activeCompanies[exhibitorId]
}

export const parseItemGallery = async (gallery) => {

  let images =  gallery.map((item, index) => {
      let source = item.src.replace("/gallery_zoom/", "/gallery_thumb/").split("/");
      return {
        name: index+"",
        sourceType: "CDN",
        source: source[source.length - 2] + "/" + source[source.length - 1]
      }
    });
  const galleryResult = await OzwolImageService.getImagesSize(images);
  // console.log("asd");
  // console.log(galleryResult);
  return gallery.map((item, index) => ({
    ...item,
    width: galleryResult[index].width,
    height: galleryResult[index].height,
  }));

}
