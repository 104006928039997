import React from 'react';
import {useTheme} from "../../../../themes";
import Icon from "../../../App/Icon";
import Wizard from "./../../../Common/Wizard";
import styled from 'styled-components';
import {useDispatch, useSelector} from "react-redux";
import {getTranslations} from "../../../../store/localization/selectors";
import {getConfig} from "../../../../store/settings/selectors";
import {showFullModal} from "./../../../../store/settings/actions";


const Wrapper = styled.div`
    display: flex;
    /* justify-content: space-between; */
    padding: 10px 15px 10px 15px;
    /* box-shadow: inset 0 3px 5px #0000000F; */
    /* background: #F8F8F8; */
    margin-top: 10px;
    ${props => props.styles}
`;

const Button = styled.button`
position: relative;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 15px;
  font-size: 11px;
  padding: 0px 10px 0px 10px;
  background-color: transparent;
  color: ${props => props.isSuggested ? "#ffffff" : (!props.active ? props.color : props.activeColor)};
  background: ${props => props.isSuggested ? "#f9cf90" : (!props.active ? props.background : props.activeBackground)};
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  margin-right: 10px;
`;
const WizardToggle = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  color: #585858;
  cursor: pointer;
  border: 2px solid #585858;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 5px;
  margin-right: -5px;
  background-color: #fff;
`;

const ResultsType = ({active, onClick}) => {

  const dispatch = useDispatch();
  const theme = useTheme();
  const config = useSelector(getConfig);
  const translations = useSelector(getTranslations);

  const { button } = theme.list.resultsTab;
  const buttonStyles = {
    background: button.normal.background,
    color: button.normal.color,
    activeBackground: button.active.background,
    activeColor: button.active.color,
  }

  const buttons = [{
    id: 'all',
    label: translations["UI"]["_result-type-all"],
    icon: null,
    enabled: true
  }, {
    id: 'favorites',
    label: translations["UI"]["_result-type-favorites"],
    icon: theme.icons.set.favorite,
    enabled: config.enable_favorites
  }, {
    id: 'recents',
    label: translations["UI"]["_result-type-recents"],
    icon: null,
    enabled: true
  }, 
  // {
  //   id: 'suggested',
  //   label: translations["UI"]["_result-type-suggested"],
  //   icon: null,
  //   enabled: config.enable_suggested
  //}
];


  return (
    buttons.filter(button => button.enabled).length > 1 ? (
      <Wrapper>
        {buttons.filter(button => button.enabled).map(button => (
          <Button key={`result-type-${button.id}`} onClick={e => onClick(button.id)} {...buttonStyles} active={button.id === active} isSuggested={button.id === "suggested"}>
            {button.icon && (<Icon size={{value: 25, unit: 'px'}} name={button.icon} color={"#d4d4d4"} />)}
            {button.label}

            {button.id === "suggested" ?
              <WizardToggle onClick={() => dispatch(showFullModal(<Wizard tab={"suggestions"} />))}>
                <Icon name={"info-1"} color={"#585858"} size={{value: 20, unit: 'px'}} />
              </WizardToggle>
            : null }
          </Button>
        ))}
      </Wrapper>
    ) : null
  );
}

export default React.memo(ResultsType);
