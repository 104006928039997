import React from 'react';
import { useLocation } from 'react-router-dom';
// import {getConfig} from './../../../store/settings/selectors';
import {getTranslations} from "./../../store/localization/selectors";
import {Helmet} from 'react-helmet';
import {useSelector} from "react-redux";
import { useTheme } from './../../themes';

const Meta = ({title = null, description = null, image = null}) => {
  const location = useLocation();
  // const config = useSelector(getConfig);
  const translations = useSelector(getTranslations);
  const theme = useTheme();

  return (
    <Helmet>
      <title>{title ? title + " | " + translations["meta"]["_meta-title"] : translations["meta"]["_meta-title"]}</title>
      <meta property="og:title" content={title ? title + " | " + translations["meta"]["_meta-title"] : translations["meta"]["_meta-title"]} />
      {description ?
        <meta name="description" content={description} />
      :
        <meta name="description" content={translations["meta"]["_meta-description"]} />
      }
      {description ?
        <meta property="og:description" content={description} />
      :
        <meta property="og:description" content={translations["meta"]["_meta-description"]} />
      }
      {image ?
        <meta property="og:image" content={image} />
      :
        <meta property="og:image" content={theme.logo.variant["default"]} />
      }
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.hostname+location.pathname} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="shortcut icon" href={theme.favicon}/>
      <meta charset="UTF-8" />
    </Helmet>
  )
}

export default Meta;
