import { createSelector } from 'reselect'

export const getConfig = createSelector(
  (state) => state.settings.config,
  config => config
)

export const getReady = createSelector(
  (state) => state.settings.ready,
  ready => ready.init && ready.translations && ready.auth
)

export const getLayout = createSelector(
  (state) => state.settings.layout,
  layout => layout
)

export const getIfMobile = createSelector(
  (state) => state.settings.isMobile,
  isMobile => isMobile
)

export const getStatus = createSelector(
  (state) => state.settings.status,
  status => status
)

export const getArea = createSelector(
  (state) => state.settings.area,
  area => area
)

export const getSubarea = createSelector(
  (state) => state.settings.subarea,
  subarea => subarea
)

export const getResultsType = createSelector(
  (state) => state.settings.resultsType,
  resultsType => resultsType
)

export const getDefaultLanguage = createSelector(
  (state) => state.settings.config.default_language,
  default_language => default_language
)

export const getLevels = createSelector(
  (state) => state.settings.filters.levels,
  levels => levels
)

export const getFilters = createSelector(
  (state) => state.settings.filters,
  filters => filters
)

export const getIsPlansModalOpen = createSelector(
  (state) => state.settings.modals.plans,
  plans => plans
)

export const getIsWelcomeModalOpen = createSelector(
  (state) => state.settings.modals.welcome,
  welcome => welcome
)

export const getIsFullModalOpen = createSelector(
  (state) => state.settings.fullModal.show,
  show => show
)
export const getFullModalContent = createSelector(
  (state) => state.settings.fullModal.content,
  content => content
)

export const getChatConfig = createSelector(
  (state) => state.settings,
  settings => settings.config.hasOwnProperty('chatConfig') ? settings.config.chatConfig : null
)

export const getTenant = createSelector(
  (state) => state.settings,
  settings => settings.config.tenant
)

export const getAccountSettings = createSelector(
  (state) => state.settings,
  settings => settings.config ? settings.config.accountSettings : null
)

export const getChatEnvByDomain = createSelector(
  (state) => state.settings.config.chatConfig,
  (chatConfig) => window && window.location && chatConfig && chatConfig.hasOwnProperty(window.location.hostname) ? chatConfig[window.location.hostname].pluginEnv : (chatConfig ? chatConfig.default.pluginEnv : 'production')
);
