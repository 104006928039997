import React, { useState, useCallback } from 'react';
import debounce from 'lodash.debounce';
import {connect} from 'react-redux';
import {compose} from "redux";
import LevelButton from './LevelButton';
import { LevelButtonContainer } from "./styled";
import {getTranslations} from "./../../../store/localization/selectors";
import styled from 'styled-components';
import { withTheme } from "../../../themes";



export const SearchWrapper = styled.div`

  width: calc(100% - 36px);
  margin: 0 auto;
  height: 46px;
  display: block;
  position: relative;
  border-radius: ${props => props.borderRadius.value + props.borderRadius.unit};
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: ${props => props.background};
`;
const SearchInput  = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 0;
  line-height: 46px;
  padding: 0 20px;
  font-size: 14px;
  background-color: transparent;
  color: ${props => props.textColor};
  outline: none;

  &:focus{
    outline: none;
  }

  &:placeholder{
    color: ${props => props.placeholderColor}
  }
`;

const mapStateToProps = (state) => ({
  translations: getTranslations(state)
})

const List = ({ theme, data, field, onClick, active, selected, onSearch, getInputRef, searchable, translations }) => {

  const [inputValue, setInputValue] = useState('');

  const debouncedSave = useCallback(
    debounce((newValue) => onSearch(newValue), 300),
    []
  );

  const updateValue = (newValue) => {
    setInputValue(newValue);
    debouncedSave(newValue);
  };

  return (
    <div className={'List'}>
      {searchable && (
        <SearchWrapper background={theme.filters.search.background} borderRadius={theme.filters.search.borderRadius}>
          <SearchInput
            ref={ref => getInputRef(ref)}
            textColor={theme.filters.search.textColor}
            placeholderColor={theme.filters.search.placeholderColor}
            value={inputValue}
            type='search'
            placeholder={translations["UI"]["Search"]}
            onChange={(input) => updateValue(input.target.value)}
            onKeyUp={(input) => updateValue(input.target.value)}
          />
        </SearchWrapper>
      )}
      <div className={'List__Content'}>
        {data.map((item, index) => (
          <LevelButtonContainer key={`filter-${field}-${index}`} >
            <LevelButton active={selected && selected.indexOf(item.encodedKey) !== -1} hideArrow={true} onClick={e => onClick(item.encodedKey)} label={item.label} count={item.doc_count} />
          </LevelButtonContainer>
        ))}
      </div>
    </div>
  );
};


export default compose(
  connect(mapStateToProps)
)(withTheme(List));
