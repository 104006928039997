import React from 'react';
// import { Link, useParams } from 'react-router-dom';
// import ReactHtmlParser from 'react-html-parser';
import styled from "styled-components";
import { useDispatch, useSelector} from "react-redux";
import { getLayout } from "./../../../store/settings/selectors";
import { getTranslations, getLocale } from "./../../../store/localization/selectors";
import {toggleFilters} from "./../../../store/settings/actions";

import {LAYOUT_XS, LAYOUT_SM} from './../../../settings';

import Logo from './../../../components/App/Logo';
// import Container from './../../../components/App/Container';
import Spacer from './../../../components/App/Spacer';
import Image from './../../../components/App/Image';
import Button from "./../../../components/App/Button";

import heroImage from './../assets/home-bg.jpg';
import heroLogo from './../assets/logo-terra.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;

  height: calc(100vh - 50px);
  height: calc(calc(var(--vh, 1vh) * 100) - 50px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  margin-top: 3px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* min-height: 100%; */
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  text-align: center;
`;



const LogoWrapper = styled.div`
  max-width: 90%;
`;

const Hero = styled.div`
  width: 100%;
  position: relative;
  padding: 30px;
  background-image: url('${props => props.background}');
  background-color: #D0D0D0;
  background-repeat: no-repeat;
`;

const HeroText = styled.div`
  font-size: ${props => props.mobile ? 20 : 28}px;
  line-height: ${props => props.mobile ? 30 : 42}px;
  color: #2A2A2A;
  font-weight: 300;
  text-transform: uppercase;

  text-align: ${props => props.mobile ? "center" : "left"};
`;
const HeroTitle = styled.div`
  font-weight: bold;
`;
const HeroLogo = styled.div`
  float: right;
`;


export default function Home(){

  const dispatch = useDispatch();
  dispatch(toggleFilters(false));

  const layout = useSelector(getLayout);
  const translations = useSelector(getTranslations);
  const lang = useSelector(getLocale);
  // const config = useSelector(getConfig);


  return (
    <Wrapper>
      <MainContainer>
        <Spacer size={{value: 54, unit: "px"}} />
        <LogoWrapper>
          <Logo height={{value: 36, unit: "px"}} variant="default" />
        </LogoWrapper>
        <Spacer size={{value: 54, unit: "px"}} />
        <Hero background={heroImage} id="hero">
          { layout === LAYOUT_XS || layout === LAYOUT_SM ?
            <Image src={heroLogo} width={{"value": 200, "unit": "px"}}/>
          :
            <HeroLogo>
              <Image src={heroLogo} width={{"value": 300, "unit": "px"}}/>
            </HeroLogo>
          }
          <HeroText mobile={layout === LAYOUT_XS || layout === LAYOUT_SM}>
            <HeroTitle>
            {translations["homepage"]["_home-title"]}
            </HeroTitle>
            {translations["homepage"]["_home-text"]}
            {/*<br/><br/>
            <a rel="noopener noreferrer" target="_blank" href={"https://www.fieragricola.it/"+lang}><Button>{translations["homepage"]["_home-cta"]}</Button></a>*/}
          </HeroText>
        </Hero>
      </MainContainer>
    </Wrapper>

  );

}
