import React from 'react';
import {Link} from 'react-router-dom';
// import {useTheme} from "../../../themes";
// import Icon from "../../App/Icon";
import Button from "../../App/Button";
import styled from 'styled-components';
import {useSelector} from "react-redux";
import {getTranslations} from "../../../store/localization/selectors";
// import {getConfig} from "../../../store/settings/selectors";

const Wrapper = styled.div`
  display: flex;
  /* justify-content: space-between; */
  padding: 10px 15px 10px 15px;
  /* box-shadow: inset 0 3px 5px #0000000F; */
  /* background: #F8F8F8; */
  margin-top: 10px;
  ${props => props.styles}
`;
const Item = styled.div`
  margin-right: 10px;
`;


const SubareasTabs = ({subareas, tenant, language, area, subarea}) => {

  // const theme = useTheme();
  // const config = useSelector(getConfig);
  const translations = useSelector(getTranslations);

  // const { button } = theme.list.resultsTab;

  return (
    Object.keys(subareas).filter(i => subareas[i].enable).length > 1 ? (
      <Wrapper>
        {Object.keys(subareas).filter(i => subareas[i].enable).map(i => (
          <Item key={subareas[i].key}>
            <Link to={"/"+language+"/"+area+"/"+i}>
              <Button onClick={e => {}} size="tiny" variant="subareas" isActive={i === subarea}>
                {translations["UI"]["_label-"+area+"-"+i]}
              </Button>
            </Link>
          </Item>
        ))}
      </Wrapper>
    ) : null
  );
}

export default React.memo(SubareasTabs);
