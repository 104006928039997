import React from 'react'
import {getParameterByName} from "../../helpers";
import {Redirect} from 'react-router-dom'

// const Authorization = () => {
//   let redirectUrl = getParameterByName('url', window.location.href).replace(window.location.origin, '');
//   return <Redirect to={redirectUrl} />;
// }

const Authorization = () => {
  const redirectUrl = getParameterByName('url', window.location.href).replace(window.location.origin, '');
  return <Redirect to={redirectUrl} />;
}

export default React.memo(Authorization)
