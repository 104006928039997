import axios from "axios";
import {DOMAINS} from "./../domains";

export class OzwolImageService {

  constructor() {

    this.getDomainConfig = this.getDomainConfig.bind(this);
    this.getApiUrl = this.getApiUrl.bind(this);
    this.getImagesSize = this.getImagesSize.bind(this);

  }

  getDomainConfig() {
    return DOMAINS[window.location.hostname];
  }

  getApiUrl() {

    const domainConfig = this.getDomainConfig().chatConfig;
    let apiUrl = domainConfig.default.apiUrl;
    if (domainConfig.hasOwnProperty(window.location.hostname))
      apiUrl = domainConfig[window.location.hostname].apiUrl;
    return apiUrl;

  }

  async getImagesSize( urls ) {
    return new Promise(async (resolve, reject) => {
      axios.defaults.headers.common['Content-Type'] = "application/json";
      axios.defaults.headers.common['X-Api-Key'] = DOMAINS[window.location.hostname].ozwol.apiKey;
      axios.defaults.headers.common['X-Project-Id'] = DOMAINS[window.location.hostname].ozwol.projectId;
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";
      axios.post(DOMAINS[window.location.hostname].ozwol.endpoint, {
        "scriptName": "getShape",
        "destination": {
          "type": "INLINE"
        },
        "parameters": {
          "image": urls
        }
      }).then(function (result) {
        let sizes = [];
        result.data.results.forEach(function(item,index){
          let key = Object.keys(item)[0];
            sizes[key] = item[key];
        })
        resolve(sizes);

      }).catch(function (error) {
        console.log(error);
        resolve(null);
      });





    });
  }

}

export default new OzwolImageService();
