import React, {Component} from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import List from "./List";
import Detail from "./Detail";
import {getProfile} from "../../../store/chat/actions";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0,0,0,0.46);
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

// const Container = styled.span`
//   width: 100%;
//   position: relative;
//   border-radius: 10px;
//   background: #f2f4f4;

//   > button {
//     position: absolute;
//     right: 0;
//     top: 0;
//   }
// `;

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

class Modal extends Component {

  state = {
    view: 'list'
  }

  setView(view) {
    const { token } = this.props;
    if (token)
      this.setState({view});
    else
      this.props.onLogin();
  }

  bindFormEvents() {
    this.$accountRef.addEventListener('submit::success', (response) => {

      this.props.onSubmit();

      // console.log(response.detail[0].roles)
      // const { roles } = response.detail[0].data;

      // if (
      //   (this.state.view === 'basic' && roles.indexOf('userBasic') !== -1) ||
      //   (this.state.view === 'premium')
      // ) {

        setTimeout(() => {
          this.props.onClose();
          this.props.dispatch(getProfile());
        }, 1000);

      // }

    })
  }

  render() {
    let { roles, canClose = true, token, onClose, pluginEnv, locale } = this.props;
    let { view } = this.state;

    return (
      <Wrapper>
        {view === 'list' ? (
          <List roles={roles} canClose={canClose} onClick={plan => this.setView(plan)} onClose={onClose}  />
        ) : (
          <Detail
            view={view === 'basic' ? 'Basic' : 'Premium3'}
            token={token}
            onBack={() => this.setView('list')}
            onClose={onClose}
            renderCallback={() => this.bindFormEvents()}
          >
            <vrf-account env={pluginEnv} locale={locale} ref={ref => this.$accountRef = ref} route={roles.length === 0 || view === 'basic' ? 'profile' : 'premium'} token={token.accessToken} />
          </Detail>
        )}
      </Wrapper>
    );
  }

}

export default connect(null, mapDispatchToProps)(Modal);
