import React, { Component } from 'react';
import { debounce } from 'lodash';
// import { replaceStrWithObj } from "../../../../helpers";
import styled from 'styled-components';
import {slugify} from "./../../../../helpers";

import Result from './Result';
import './styles.scss';
import Loader from "../../../Common/Loader";

const Container = styled.div`
  width: calc(100% - 30px);
  margin: 0px 15px;
`;

const LoaderContainer = styled.div`
  text-align: center;
  margin: 20px 0;
`;

class ResultsList extends Component {

  state = {
    isLoading: false
  }

  constructor(props) {
    super(props);

    this.props = props;

    this.handleOnScroll = this.handleOnScroll.bind(this);
  }

  componentDidMount() {

    this.$container.addEventListener('scroll', debounce(this.handleOnScroll, 10, {
      leading: false,
      trailing: true
    }))

  }

  handleOnScroll() {

    const innerHeight = this.$inner.getBoundingClientRect().height - this.$container.getBoundingClientRect().height;
    const scrollOffset = this.$container.scrollTop;
    if (scrollOffset > innerHeight * 0.5 /*&& !this.props.end*/ && !this.props.loading && !this.props.end) {
      this.props.onLoadMore();
    }

  }

  componentWillUnmount() {
    this.$container.removeEventListener('scroll', this.handleOnScroll);
  }

  render() {
    const { className, loading, end, items, area, enableLogin, enableFavorite, noResultsLabel, noMoreResultsLabel, detailBaseUrl, resultsType, onItemClick } = this.props;
    return (
      <div className={className} ref={ref => this.$container = ref}>
        <div ref={ref => this.$inner = ref}>
        {(items.length === 0 && !loading) ? <div className="ResultsList_Ended">{noResultsLabel}</div> : items.map(item => item ?
          <Container key={item.id}>
            <Result
              onClick={onItemClick}
              resultsType={resultsType}
              enableLogin={enableLogin}
              enableFavorite={enableFavorite}
              item={item}
              area={area}
              tags={item.tags}
              tagsPosition={area === 'companies' ? 'bottom' : 'top'}
              uppertext={item.uppertext}
              title={item.name}
              lowertext={item.lowertext }
              url={`${detailBaseUrl}/${item.id}${area === "companies" ? "/"+slugify(item.options.name) : ""}`}
              slug={item.slug}
              isActive={false}
              isFavorite={false}
            />
          </Container> : null)}
          {loading ? <LoaderContainer><Loader size={{value: 30, unit: 'px'}} /></LoaderContainer>
          : (items.length > 0 && end ? <div className="ResultsList_Ended">{noMoreResultsLabel}</div> : null)}

        </div>
      </div>
    );

  }

}

ResultsList.defaultProps = {
 noResultsLabel: '!Nessun risultato',
 noMoreResultsLabel: '!Nessun altro risultato'
}

export default ResultsList;
