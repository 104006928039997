import React, {Component} from 'react';

class LetsFair extends Component {

    interval = null;
    tries = 0;

    componentDidMount() {
        // console.log('[LETZFAIR] Init')
        this.loadAPI();
    }

    /**
     * window.letzfair= window.letzfair || function(){(letzfair.q=letzfair.q||[]).push(arguments)};letzfair.l=+new Date;
     letzfair('key_test');

     */

    loadAPI() {

        // console.log('loadAPI');

        const scriptExists = document.getElementById('letsfair-api');
        if (!scriptExists) {

            const script = document.createElement('script')
            script.id = 'letsfair-api';
            script.src = this.props.jsUrl
            document.body.append(script);

            this.interval = setInterval(() => {
                if (this.tries > 10) {
                    clearInterval(this.interval);
                }
                if (window.letzfair) {
                    this.loadApiCallback();
                }
            }, 500)

            // console.log('[LETZFAIR] Lib loaded')
        }


    }

    loadApiCallback() {

        clearInterval(this.interval);
        window._letzfair.api_key = this.props.apiKey;
        window.letzfair(this.props.apiKey);

    }

    render() {

        return <div/>

    }

}

export default LetsFair;
