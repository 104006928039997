import { useEffect } from "react";
import { useSelector } from "react-redux";
import AnalyticsService from '../../services/AnalyticsService';
import {getLocale} from "../../store/localization/selectors";
import {useLocation} from "react-router";

const Analytics = ({name, listType, id , ...props}) => {

  const lang = useSelector(getLocale);
  const { pathname } = useLocation();

  useEffect(() => {
    AnalyticsService.sendPageView({name, listType, id, lang, pathname})
  }, [id, lang, listType, name, pathname])

  return null;

}

export default Analytics;
