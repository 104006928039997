import produce from "immer"
import {handleActions} from 'redux-actions';
import {
  init,
  setArea,
  setLayout,
  setLevels,
  setReady,
  setResultsType,
  setStatus,
  toggleFilters,
  toggleModal,
  toggleSubareas,
  showFullModal,
  hideFullModal
} from './actions'
import {loadTranslation} from "../localization/actions";
import {check as checkAuth, profile} from "../auth/actions";

const INITIAL_REQUEST_STATE = {
  init: { loading: false, error: null}
}

const INITIAL_STATE = {
  area: null,
  config: null,
  layout: null,
  isMobile: false,
  subarea: null,
  resultsType: 'all',
  filters: {
    area: null,
    levels: [],
    levelsDepth: 0,
    direct: 0
  },
  modals: {
    favorite: false,
    plans: false,
      welcome: false,
  },
  fullModal:{
    show: false,
    content: null
  },
  status: {
    isSubareaOpen: false,
    isFilterOpen: false,
    isMoreOpen: false
  },
  ready: {
    init: false,
    translations: false,
    auth: false
  },
  requests: INITIAL_REQUEST_STATE,
}

const reducer = handleActions({

  [init.TRIGGER]: (state) => produce(state, draft => {
    // draft.requests.init.loading = true
    // draft.requests.init.error = null
  }),

  [init.SUCCESS]: (state, { payload }) => produce(state, draft => {
    draft.config = payload;
    draft.ready = {...state.ready, init: true};
    // draft.requests.init.loading = false
  }),

  [init.FAILURE]: (state, { payload }) => produce(state, draft => {
    // draft.requests.init.loading = false
    // draft.requests.init.error = payload
  }),

  [setLayout.TRIGGER]: (state, { payload }) => produce(state, draft => {
    draft.layout = payload.layout;
    draft.isMobile = payload.isMobile;
  }),

  [setStatus.TRIGGER]: (state, { payload }) => produce(state, draft => {
    draft.status = Object.assign({}, state.status, payload);
  }),

  [setLevels.TRIGGER]: (state, { payload }) => produce(state, draft => {
    draft.filters = Object.assign({}, state.filters, payload);
  }),

  [setReady.TRIGGER]: (state, { payload }) => produce(state, draft => {
    // draft.ready = true
  }),

  [setArea.TRIGGER]: (state, { payload }) => produce(state, draft => {
    draft.area = payload.area;
    draft.subarea = payload.subarea;
    // draft.resultsType = 'all';
    draft.status.isSubareaOpen = false;
    // state.products.count_initial = null;

    if (state.area !== payload.area) {
      draft.status.isFilterOpen = false;
    }
  }),

  [setArea.FAILURE]: (state, { payload }) => produce(state, draft => {}),

  [toggleFilters.TRIGGER]: (state, { payload }) => produce(state, draft => {
    draft.status.isFilterOpen = payload !== undefined ? payload : !state.status.isFilterOpen;
    if (state.status.isFilterOpen) {
      draft.status.isSubareaOpen = false;
    }
  }),

  [toggleSubareas.TRIGGER]: (state, { payload }) => produce(state, draft => {

    draft.status.isSubareaOpen = payload !== undefined ? payload : !state.status.isSubareaOpen;
    if (draft.status.isSubareaOpen && !state.status.isFilterOpen) {
      draft.status.isFilterOpen = true
    }

    // clicco quando i filtri sono chiusi: filtro = true, sub = true
    // clicco quando i filtri sono aperti e sub è chiuso: sub = true
    // clicck quando i filtri sono aperti e sub è aperto: sub = false

    // draft.status.isSubareaOpen = payload !== undefined ? payload : !state.status.isFilterOpen;
    // if (!(payload !== undefined && payload === false)) {
    //   draft.status.isFilterOpen = !state.status.isSubareaOpen;
    // }
  }),

  [setResultsType.SUCCESS]: (state, { payload }) => produce(state, draft => {
    draft.resultsType = payload;
    draft.status.isFilterOpen = false;
  }),

  [toggleModal.TRIGGER]: (state, { payload }) => produce(state, draft => {

    if (state.modals.hasOwnProperty(payload)) {
      draft.modals[payload] = !state.modals[payload]
    }

  }),

  [showFullModal.TRIGGER]: (state, { payload }) => produce(state, draft => {
    // if (state.fullModal.hasOwnProperty(payload)) {
      draft.fullModal.show = true;
      draft.fullModal.content = payload;

  }),

  [hideFullModal.TRIGGER]: (state) => produce(state, draft => {
    // if (state.fullModal.hasOwnProperty(payload)) {
      draft.fullModal.show = false;
      draft.fullModal.content = null;
    // }
  }),

  [loadTranslation.SUCCESS]: (state) => produce(state, draft => {
    draft.ready = {...state.ready, translations: true};
  }),

  [profile.SUCCESS]: (state) => produce(state, draft => {
    draft.ready = {...state.ready, auth: true};
  }),

  [checkAuth.FAILURE]: (state) => produce(state, draft => {
    draft.ready = {...state.ready, auth: true};
  })

}, INITIAL_STATE);

export default reducer
