import { LAYOUT_XS, LAYOUT_SM, LAYOUT_MD, LAYOUT_LG } from "./../../settings"

import logoDefault from "./assets/logo.svg";
import logoPlus from "./assets/logo-plus.svg";
import logoIcon from "./assets/logo-icon.svg";
import logoSpecialEdition from "./assets/logo-special-edition.svg";
import placeholderDefault from "./assets/placeholder.svg";
import placeholderUser from "./assets/user-placeholder.jpg";
import placeholderProduct from "./assets/product-placeholder.png";
import idleImageCompanies from "./assets/idlepage-companies.svg";
import idleImageProducts from "./assets/idlepage-products.svg";
import idleImageEvents from "./assets/idlepage-events.svg";
import idleImageBcards from "./assets/idlepage-bcards.svg";
import idleImageChat from "./assets/idlepage-chat.svg";
import faviconImage from "./assets/favicon.ico";


import hallVoid from "./assets/hall-void.svg";
import hallP1 from "./assets/hall-P1.svg";
import hallP2 from "./assets/hall-P2.svg";
import hallP3 from "./assets/hall-P3.svg";
import hallP4 from "./assets/hall-P4.svg";
import hallP5 from "./assets/hall-P5.svg";
import hallP6 from "./assets/hall-P6.svg";
import hallP7 from "./assets/hall-P7.svg";
import hallP8 from "./assets/hall-P8.svg";
import hallP9 from "./assets/hall-P9.svg";
import hallP10 from "./assets/hall-P10.svg";
import hallP11 from "./assets/hall-P11.svg";
import hallP12 from "./assets/hall-P12.svg";
import hallPA from "./assets/hall-PA.svg";
import hallPB from "./assets/hall-PB.svg";
import hallPC from "./assets/hall-PC.svg";
import hallPD from "./assets/hall-PD.svg";
import hallPF from "./assets/hall-PF.svg";
import hallPH from "./assets/hall-PH.svg";
import hallPI from "./assets/hall-PI.svg";
import hallCSA from "./assets/hall-CSA.svg";
import hallCSC from "./assets/hall-CSC.svg";
import hallCSE from "./assets/hall-CSE.svg";
import hallCSS from "./assets/hall-CSS.svg";
import hallPALAEXPO from "./assets/hall-PALAEXPO.svg";
import hallAH from "./assets/hall-AH.svg";
import hallAE from "./assets/hall-AE.svg";

export const VIR = {
  logo: {
    variant: {
      "default": logoDefault,
      "plus": logoPlus,
      "icon": logoIcon,
      "special-edition": logoSpecialEdition
    }
  },
  halls:{
    "void": hallVoid,
    "1": hallP1,
    "2": hallP2,
    "3": hallP3,
    "4": hallP4,
    "5": hallP5,
    "6": hallP6,
    "7": hallP7,
    "8": hallP8,
    "8N": hallP8,
    "9": hallP9,
    "9N": hallP9,
    "10": hallP10,
    "10N": hallP10,
    "11": hallP11,
    "11N": hallP11,
    "12": hallP12,
    "12N": hallP12,
    "A": hallPA,
    "TENSOA": hallPA,
    "B": hallPB,
    "TENSOB": hallPB,
    "C": hallPC,
    "TENSOC": hallPC,
    "C-SAG": hallPC,
    "D": hallPD,
    "TENSOD": hallPD,
    "F": hallPF,
    "F-VIR": hallPF,
    "H": hallPH,
    "H-VIR": hallPH,
    "I": hallPI,
    "I-VIR": hallPI,
    "TENSOD-11": hallPI,
    "CSA": hallCSA,
    "G6-7": hallCSA,
    "CSC": hallCSC,
    "G2-3": hallCSC,
    "CSE": hallCSE,
    "G4-5": hallCSE,
    "CSS": hallCSS,
    "G11-12": hallCSS,
    "EXPOINT": hallPALAEXPO,
    "EXPO": hallPALAEXPO,
    "C2-4": hallAH,
    "C9-11": hallAE,
  },
  color: "#7B3572",
  favicon: faviconImage,
  loading:{
    variant: {
      "default": {
        color: "#7B3572"
      }
    }
  },
  placeholder:  placeholderDefault,
  placeholders: {
    default: placeholderDefault,
    user: placeholderUser,
    product: placeholderProduct
  },
  background:{
    homepage: "#ffffff",
    global: "#F2F4F4"
  },
  icons: {
    set: {
      openFilters: "filters-1",
      closeFilters: "close-1",
      clearFilters: "trash-1",
      backFilters: "arrow-left-2",
      memo: "edit-1",
      contact: "contact-1",
      chat: "chat-1",
      calendar: "calendar-1",
      favorite: "favorite-5",
      homepage: "home-2",
      more: "more-1",
      list: "list-1",
      menuitemPrev: "arrow-left-2",
      menuitemNext: "arrow-right-2",
      language: "language-1",
      chunk: "arrow-bottom-2",
      login: "login-1",
      logout: "logout-1",
      profile: "vinitaly-1",
      checked: "tick-1",
      highlightedPost: "flag-1",
      recommendedPost: "recommended-1",
      timestampPost: "clock-1",
      menu: "menu-1",
      loading: "spinner-1",
      helpdesk: "helpdesk-1",
      legal: "legal"
    },
    scale: {
      normal: 0.7,
      wrapped: 0.5
    }
  },
  container: {
    [LAYOUT_XS]: {
      width: {
        value: "calc(100% - 30px)",
        unit: ""
      }
    },
    [LAYOUT_SM]: {
      width: {
        value: "calc(100% - 50px)",
        unit: ""
      }
    },
    [LAYOUT_MD]: {
      width: {
        value: "calc(100% - 50px)",
        unit: ""
      }
    },
    [LAYOUT_LG]: {
      width: {
        value: 990,
        unit: "px"
      }
    }
  },
  slider:{
    dots:{
      size: {
        value: 10,
        unit: "px"
      },
      spacing: {
        value: 10,
        unit: "px"
      },
      borderRadius: {
        value: 10,
        unit: "px"
      },
    },
    arrows:{
      width: {
        value: 10,
        unit: "px"
      },
      height: {
        value: 10,
        unit: "px"
      },
      size: {
        value: 10,
        unit: "px"
      },
      offset: {
        value: 10,
        unit: "px"
      },
      borderRadius: {
        value: 0,
        unit: "px"
      },
    },
    variant: {
      "default": {
        dots:{
          colorActive: "#7B3572",
          colorUnactive: "#dedede"
        },
        arrows:{
          background: "#ffffff",
          color: "#585858",
          border: "#dedede"
        }
      }
    }
  },
  userpanel:{
    background: "#F2F4F4",
    menuitemVariantBar: "grey",
    menuitemVariantItem: "default",
    version: {
      background: "#e6e6e6",
      color: "#999999"
    }
  },
  idlepage:{
    image: {
      companies: idleImageCompanies,
      products: idleImageProducts,
      events: idleImageEvents,
      bcards: idleImageBcards,
      chat: idleImageChat
    }

  },
  topbar:{
    height: {
      value: 50,
      unit: "px"
    },
    background: "#ffffff",
    borderBottom: "0px solid transparent",
    boxShadow: "0px 3px 5px #0000000F",
    textColor: "#585858",
    badgeVariant: "default",
    buttonVariant: "default",
    enable:{

    },
    logo:{
      variant: "icon",
      height: {
        value: 30,
        unit: "px"
      },
      width: {
        value: 30,
        unit: "px"
      },
      alignment: "C", // L C R
    },
    language:{
      borderSize: 2,
      borderStyle: "solid",
      borderColor: "#585858",
      color: "#585858",
    },
    icon:{
      normal:{
        color: "#7B3572",
        background: null
      },
      hover:{
        color: "#7B3572",
        background: null
      },
      active:{
        color: "#7B3572",
        background: null
      }
    },
    item:{
      padding: {
        top:{
          value: 6,
          unit: "px"
        },
        left:{
          value: 15,
          unit: "px"
        },
        bottom:{
          value: 6,
          unit: "px"
        },
        right:{
          value: 15,
          unit: "px"
        }
      },
      textFont: null,
      textSize: 15,
      textWeight: 600,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null,
      borderSize: 0,
      borderStyle: "solid",
      borderRadius: {
        value: 20,
        unit: "px"
      },
      state:{
        normal:{
          textColor: "#585858",
          borderColor: "transparent",
          background: "transparent"
        },
        hover:{
          textColor: "#7B3572",
          borderColor: "transparent",
          background: "#7B357217"
        },
        active:{
          textColor: "#7B3572",
          borderColor: "transparent",
          background: "#7B357217"
        },
        disabled:{
          textColor: "#AAAAAA",
          borderColor: "transparent",
          background: "transparent"
        }
      }

    },

    desktop:{
      height: {
        value: 60,
        unit: "px"
      },
      background: "#ffffff",
      textColor: "#585858",
      badgeVariant: "default",
      enable:{
        website: true,
        homeButton: true,
        updateBadge: true
      },
      icon:{
        normal:{
          color: "#7B3572",
          background: null
        },
        hover:{
          color: "#7B3572",
          background: null
        },
        active:{
          color: "#7B3572",
          background: null
        }
      }
    },
    mobile:{
      height: {
        value: 60,
        unit: "px"
      },
      background: "#ffffff",
      textColor: "#585858",
      badgeVariant: "default",
      enable:{
        website: true,
        homeButton: true,
        updateBadge: true
      },
      logo:{
        variant: "default",
        height: {
          value: 40,
          unit: "px"
        },
        width: {
          value: 100,
          unit: "%"
        },
        alignment: "C", // L C R
      },
      icon:{
        normal:{
          color: "#7B3572",
          background: null
        },
        hover:{
          color: "#7B3572",
          background: null
        },
        active:{
          color: "#7B3572",
          background: null
        }
      }
    }
  },
  list:{
    width: {
      value: 380,
      unit: "px"
    },
    levelTransition: 0.3, // in seconds
    background: "transparent",
    textColor: "#000000",
    logo:{
      variant: "default",
      height: {
        value: 40,
        unit: "px"
      },
      width: {
        value: 340,
        unit: "px"
      },
      alignment: "C", // L C R
    },
    resultsTab: {
      button: {
        normal: {
          background: "transparent",
          color: "#585858"
        },
        active: {
          background: "#ffffff",
          color: "#585858"
        }
      }
    },
    search:{
      borderStyle: "solid",
      borderSize: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
      },
      borderColor: "transparent",
      borderRadius: {
        value: 46,
        unit: "px"
      },
      background: "#ffffff",
      textColor: "#585858",
      placeholderColor: "#ffffff",
      icon:{
        normal:{
          color: "#7B3572",
          background: "#ffffff"
        },
        hover:{
          color: "#7B3572",
          background: "#ffffff"
        },
        active:{
          color: "#fff",
          background: "#7B3572",
        }
      }
    }
  },
  filters: {
    width: {
      value: 380,
      unit: "px"
    },
    item: {
      menuitemVariant: "filter",
      badgeVariant: "filter"
    },
    textColor: "#585858",
    search:{
      borderStyle: "solid",
      borderSize: 0,
      borderColor: "transparent",
      borderRadius: {
        value: 46,
        unit: "px"
      },
      background: "#ffffff",
      textColor: "#585858",
      placeholderColor: "#ffffff",
    },
    bar:{
      iconSize: {
        value: 40,
        unit: "px"
      },
      textSize: 14,
      textColor: "#585858",
      textWeight: "400",
      icon:{
        normal:{
          color: "#585858",
          background: "transparent"
        },
        hover:{
          color: "#585858",
          background: "#ffffff"
        },
        active:{
          color: "#585858",
          background: "ffffff"
        }
      }
    },
    tag:{
      icon:{
        color: "#000000"
      }
    },
    background: "#EBEDED",
    buttonVariant: "default-inverse",

  },
  homepage: {
    menuitemVariant: "homepage",
    menuitemSize: "tiny",
    fullsize: true
  },
  actionbar:{
    borderStyle: "solid",
    borderSize: 1,
    borderRadius: {
      value: 100,
      unit: "px"
    },
    variant: {
      "default": {
        normal: {
          textColor: "#585858",
          iconColor: "#d4d4d4",
          borderColor: "#EDEDED",
          background: "#ffffff"
        },
        hover: {
          textColor: "#585858",
          iconColor: "#d4d4d4",
          borderColor: "#7B3572",
          background: "#ffffff"
        },
        active: {
          textColor: "#585858",
          iconColor: "#7B3572",
          borderColor: "#EDEDED",
          background: "#ffffff"
        },
        disabled: {
          textColor: "#58585880",
          iconColor: "#d4d4d480",
          borderColor: "#EDEDED80",
          background: "#ffffff80"
        }
      }
    }
  },
  chunk:{
    borderStyle: "solid",
    borderSize: 0,
    borderRadius: {
      value: 12,
      unit: "px"
    },
    padding:{
      [LAYOUT_XS]: {
        top:{
          value: 15,
          unit: "px"
        },
        left:{
          value: 15,
          unit: "px"
        },
        bottom:{
          value: 0,
          unit: "px"
        },
        right:{
          value: 15,
          unit: "px"
        }
      },
      [LAYOUT_SM]: {
        top:{
          value: 15,
          unit: "px"
        },
        left:{
          value: 15,
          unit: "px"
        },
        bottom:{
          value: 0,
          unit: "px"
        },
        right:{
          value: 15,
          unit: "px"
        }
      },
      [LAYOUT_MD]: {
        top:{
          value: 15,
          unit: "px"
        },
        left:{
          value: 15,
          unit: "px"
        },
        bottom:{
          value: 0,
          unit: "px"
        },
        right:{
          value: 15,
          unit: "px"
        }
      },
      [LAYOUT_LG]: {
        top:{
          value: 20,
          unit: "px"
        },
        left:{
          value: 20,
          unit: "px"
        },
        bottom:{
          value: 0,
          unit: "px"
        },
        right:{
          value: 20,
          unit: "px"
        }
      }
    },
    bar: {
      height: {
        value: 60,
        unit: "px"
      },
      padding:{
        [LAYOUT_XS]: {
          top:{
            value: 0,
            unit: "px"
          },
          left:{
            value: 0,
            unit: "px"
          },
          bottom:{
            value: 15,
            unit: "px"
          },
          right:{
            value: 0,
            unit: "px"
          }
        },
        [LAYOUT_SM]: {
          top:{
            value: 0,
            unit: "px"
          },
          left:{
            value: 0,
            unit: "px"
          },
          bottom:{
            value: 15,
            unit: "px"
          },
          right:{
            value: 0,
            unit: "px"
          }
        },
        [LAYOUT_MD]: {
          top:{
            value: 0,
            unit: "px"
          },
          left:{
            value: 0,
            unit: "px"
          },
          bottom:{
            value: 15,
            unit: "px"
          },
          right:{
            value: 0,
            unit: "px"
          }
        },
        [LAYOUT_LG]: {
          top:{
            value: 0,
            unit: "px"
          },
          left:{
            value: 0,
            unit: "px"
          },
          bottom:{
            value: 20,
            unit: "px"
          },
          right:{
            value: 0,
            unit: "px"
          }
        }
      },
      textFont: null,
      textSize: 13,
      textWeight: 700,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null,
    },
    content: {
      borderStyle: "solid",
      borderSize: 0,
      padding:{
        [LAYOUT_XS]: {
          top:{
            value: 0,
            unit: "px"
          },
          left:{
            value: 0,
            unit: "px"
          },
          bottom:{
            value: 15,
            unit: "px"
          },
          right:{
            value: 0,
            unit: "px"
          }
        },
        [LAYOUT_SM]: {
          top:{
            value: 0,
            unit: "px"
          },
          left:{
            value: 0,
            unit: "px"
          },
          bottom:{
            value: 15,
            unit: "px"
          },
          right:{
            value: 0,
            unit: "px"
          }
        },
        [LAYOUT_MD]: {
          top:{
            value: 0,
            unit: "px"
          },
          left:{
            value: 0,
            unit: "px"
          },
          bottom:{
            value: 15,
            unit: "px"
          },
          right:{
            value: 0,
            unit: "px"
          }
        },
        [LAYOUT_LG]: {
          top:{
            value: 0,
            unit: "px"
          },
          left:{
            value: 0,
            unit: "px"
          },
          bottom:{
            value: 20,
            unit: "px"
          },
          right:{
            value: 0,
            unit: "px"
          }
        },
      },
      textFont: null,
      textSize: null,
      textWeight: null,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null,
    },
    variant: {
      "default":{
        borderColor: "transparent",
        boxShadow: "0px 3px 5px #5858580F",
        background: "#ffffff",
        bar: {
          background: null,
          textColor: "#7B3572",
          icon: {
            normal: {
              color: "#585858",
              background: null
            }
          }
        },
        content: {
          inforowVariant: "default",
          borderColor: "transparent",
          background: null,
          textColor: "#585858"
        }
      },
      "event":{
        borderColor: "transparent",
        boxShadow: "0px 3px 5px #5858580F",
        background: "#ffffff",
        bar: {
          background: null,
          textColor: "#079192",
          icon: {
            normal: {
              color: "#079192",
              background: null
            }
          }
        },
        content: {
          inforowVariant: "default",
          borderColor: "transparent",
          background: null,
          textColor: "#585858"
        }
      },
      "grey":{
        borderColor: "transparent",
        boxShadow: "0px 3px 5px #5858580F",
        background: "#DDDDDD",
        bar: {
          background: null,
          textColor: "#7B3572",
          icon: {
            normal: {
              color: "#7B3572",
              background: null
            }
          }
        },
        content: {
          inforowVariant: "default",
          borderColor: "transparent",
          background: null,
          textColor: "#7B3572"
        }
      },
      "event-grey":{
        borderColor: "transparent",
        boxShadow: "0px 3px 5px #5858580F",
        background: "#DDDDDD",
        bar: {
          background: null,
          textColor: "#079192",
          icon: {
            normal: {
              color: "#079192",
              background: null
            }
          }
        },
        content: {
          inforowVariant: "default",
          borderColor: "transparent",
          background: null,
          textColor: "#079192"
        }
      },
      "dark":{
        borderColor: "transparent",
        boxShadow: "0px 3px 5px #5858580F",
        background: "#585858",
        bar: {
          background: null,
          textColor: "#ffffff",
          icon: {
            normal: {
              color: "#ffffff",
              background: null
            }
          }
        },
        content: {
          inforowVariant: "default",
          borderColor: "transparent",
          background: null,
          textColor: "#ffffff"
        }
      }
    }
  },
  post:{
    borderStyle: "solid",
    borderSize: 0,
    borderRadius: {
      value: 12,
      unit: "px"
    },
    padding:{
      [LAYOUT_XS]: {
        top:{
          value: 15,
          unit: "px"
        },
        left:{
          value: 15,
          unit: "px"
        },
        bottom:{
          value: 15,
          unit: "px"
        },
        right:{
          value: 15,
          unit: "px"
        }
      },
      [LAYOUT_SM]: {
        top:{
          value: 15,
          unit: "px"
        },
        left:{
          value: 15,
          unit: "px"
        },
        bottom:{
          value: 15,
          unit: "px"
        },
        right:{
          value: 15,
          unit: "px"
        }
      },
      [LAYOUT_MD]: {
        top:{
          value: 15,
          unit: "px"
        },
        left:{
          value: 15,
          unit: "px"
        },
        bottom:{
          value: 15,
          unit: "px"
        },
        right:{
          value: 15,
          unit: "px"
        }
      },
      [LAYOUT_LG]: {
        top:{
          value: 20,
          unit: "px"
        },
        left:{
          value: 20,
          unit: "px"
        },
        bottom:{
          value: 20,
          unit: "px"
        },
        right:{
          value: 20,
          unit: "px"
        }
      }
    },
    tags: {
      textFont: null,
      textSize: 11,
      textWeight: null,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null
    },
    title: {
      textFont: null,
      textSize: 15,
      textWeight: 700,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null
    },
    content: {
      textFont: null,
      textSize: 13,
      textWeight: null,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null
    },
    highlighted: {
      textFont: null,
      textSize: 13,
      textWeight: 700,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null
    },
    recommended: {
      textFont: null,
      textSize: 13,
      textWeight: 700,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null
    },
    footer: {
      textFont: null,
      textSize: 11,
      textWeight: null,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null,
      borderStyle: "solid",
      borderSize: 1
    },
    cta:{
      alignment: "center"
    },
    variant: {
      "default":{
        borderColor: "transparent",
        boxShadow: "0px 3px 5px #5858580F",
        background: "#ffffff",
        title: {
          textColor: "#585858"
        },
        content: {
          textColor: "#585858"
        },
        tags: {
          textColor: "#7B3572"
        },
        highlighted: {
          textColor: "#7B3572"
        },
        recommended: {
          textColor: "#7B3572"
        },
        footer: {
          textColor: "#585858",
          borderColor: "#d4d4d4",
        },
        buttonSize: "tiny",
        buttonVariant: "default-o"
      },
    }
  },
  fullModal:{
    borderStyle: "solid",
    borderSize: 0,
    borderRadius: {
      value: 12,
      unit: "px"
    },
    padding:{
      [LAYOUT_XS]: {
        top:{
          value: 15,
          unit: "px"
        },
        left:{
          value: 15,
          unit: "px"
        },
        bottom:{
          value: 15,
          unit: "px"
        },
        right:{
          value: 15,
          unit: "px"
        }
      },
      [LAYOUT_SM]: {
        top:{
          value: 15,
          unit: "px"
        },
        left:{
          value: 15,
          unit: "px"
        },
        bottom:{
          value: 15,
          unit: "px"
        },
        right:{
          value: 15,
          unit: "px"
        }
      },
      [LAYOUT_MD]: {
        top:{
          value: 15,
          unit: "px"
        },
        left:{
          value: 15,
          unit: "px"
        },
        bottom:{
          value: 15,
          unit: "px"
        },
        right:{
          value: 15,
          unit: "px"
        }
      },
      [LAYOUT_LG]: {
        top:{
          value: 20,
          unit: "px"
        },
        left:{
          value: 20,
          unit: "px"
        },
        bottom:{
          value: 20,
          unit: "px"
        },
        right:{
          value: 20,
          unit: "px"
        }
      }
    },
    variant: {
      "default":{
        borderColor: "transparent",
        boxShadow: "0px 3px 5px #5858580F",
        background: "#ffffff"
      },
    }
  },

  mobileheader:{
    textFont: null,
    textSize: null,
    textWeight: 600,
    textLineHeight: null,
    textLetterSpacing: null,
    textTransform: null,
    textColor: "#585858",
    icon: {
      normal: {
        color: "#585858",
        background: "transparent"
      }
    }
  },
  infoRow:{
    bar: {
      borderStyle: "solid",
      borderSize: 1,
      height: {
        value: 60,
        unit: "px"
      },
      padding: {
        top: {
          value: 15,
          unit: "px"
        },
        left: {
          value: 0,
          unit: "px"
        },
        bottom: {
          value: 15,
          unit: "px"
        },
        right: {
          value: 0,
          unit: "px"
        }
      }
    },
    label: {
      textFont: null,
      textSize: 13,
      textWeight: 700,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null,
    },
    value: {
      textFont: null,
      textSize: 13,
      textWeight: 400,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null,
    },
    children:{
      padding: {
        top: {
          value: 15,
          unit: "px"
        },
        left: {
          value: 0,
          unit: "px"
        },
        bottom: {
          value: 30,
          unit: "px"
        },
        right: {
          value: 0,
          unit: "px"
        }
      },
      textFont: null,
      textSize: 13,
      textWeight: 400,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null,
    },
    variant: {
      "default": {
        bar: {
          borderColor: "#DDDDDDCC"
        },
        label: {
          textColor: "#585858"
        },
        value: {
          textColor: "#585858"
        },
        children: {
          textColor: "#585858"
        }
      },
      "primary": {
        bar: {
          borderColor: "#7B3572"
        },
        label: {
          textColor: "#7B3572"
        },
        value: {
          textColor: "#585858"
        },
        children: {
          textColor: "#585858"
        }
      },
      "event": {
        bar: {
          borderColor: "#079192"
        },
        label: {
          textColor: "#079192"
        },
        value: {
          textColor: "#585858"
        },
        children: {
          textColor: "#585858"
        }
      },
      "event-noborder": {
        bar: {
          borderColor: "#079192"
        },
        label: {
          textColor: "#079192"
        },
        value: {
          textColor: "#585858"
        },
        children: {
          textColor: "#585858"
        }
      }
    }
  },
  sheetTopbar:{
    bar: {
      borderStyle: "solid",
      borderSize: 0,
      height: {
        value: 60,
        unit: "px"
      },
      padding: {
        top: {
          value: 15,
          unit: "px"
        },
        left: {
          value: 0,
          unit: "px"
        },
        bottom: {
          value: 15,
          unit: "px"
        },
        right: {
          value: 0,
          unit: "px"
        }
      }
    },
    title: {
      textFont: null,
      textSize: null,
      textWeight: 400,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null,
    },
    variant: {
      "default": {
        anchorLinkVariant: "default",
        bar: {
          background: "#7B3572",
          boxShadow: "0px 3px 5px #5858580F",
          borderColor: "transparent"
        },
        title: {
          textColor: "#ffffff"
        }
      },
      "event": {
        anchorLinkVariant: "default",
        bar: {
          background: "#079192",
          boxShadow: "0px 3px 5px #5858580F",
          borderColor: "transparent"
        },
        title: {
          textColor: "#ffffff"
        }
      }
    }
  },
  anchors:{
    spacing: 15,
    link: {

      textFont: null,
      textSize: null,
      textWeight: 400,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null,
    },
    variant: {
      "default": {
        link: {
          normal: {
            textColor: "#ffffff66"
          },
          hover: {
            textColor: "#ffffff"
          },
          active: {
            textColor: "#ffffff"
          }
        }
      }
    }
  },

  typography: {
    text: {
      textColor: "#585858",
      textFont: "Arial, sans-serif",
      textSize: 13,
      textWeight: 400,
      textLineHeight: 18,
      textLetterSpacing: null,
      textTransform: null
    },
    h1: {
      textColor: "#585858",
      textFont: null,
      textSize: 50,
      textWeight: 400,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null
    },
    h2: {
      textColor: "#585858",
      textFont: null,
      textSize: 40,
      textWeight: 400,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null
    },
    h3: {
      textColor: "#585858",
      textFont: null,
      textSize: 16,
      textWeight: 400,
      textLineHeight: null,
      textLetterSpacing: null,
      textTransform: null
    }

  },
  button:{

    textFont: null,
    textLetterSpacing: null,
    textTransform: null,
    borderStyle: "solid",
    size: {
      "default": {
        textSize: 12,
        textWeight: 400,
        textLineHeight: null,
        borderSize: 2,
        borderRadius: {
          value: 100,
          unit: "px"
        },
        padding: {
          top:{
            value: 8,
            unit: "px"
          },
          left:{
            value: 20,
            unit: "px"
          },
          bottom:{
            value: 8,
            unit: "px"
          },
          right:{
            value: 20,
            unit: "px"
          }
        },
      },
      "tiny":{
        textSize: 12,
        textWeight: 400,
        textLineHeight: null,
        borderSize: 2,
        borderRadius: {
          value: 100,
          unit: "px"
        },
        padding: {
          top:{
            value: 4,
            unit: "px"
          },
          left:{
            value: 10,
            unit: "px"
          },
          bottom:{
            value: 4,
            unit: "px"
          },
          right:{
            value: 10,
            unit: "px"
          }
        },
      }
    },
    variant:{
      "default": {
        normal:{
          textColor: "#ffffff",
          borderColor: "#7B3572",
          background: "#7B3572"
        },
        hover:{
          textColor: "#7B3572",
          borderColor: "#7B3572",
          background: "#ffffff"
        },
        active:{
          textColor: "#7B3572",
          borderColor: "#7B3572",
          background: "#ffffff"
        },
        disabled:{
          textColor: "#dedede",
          borderColor: "#dedede",
          background: "#ffffff"
        }
      },
      "event": {
        normal:{
          textColor: "#ffffff",
          borderColor: "#079192",
          background: "#079192"
        },
        hover:{
          textColor: "#079192",
          borderColor: "#079192",
          background: "#ffffff"
        },
        active:{
          textColor: "#079192",
          borderColor: "#079192",
          background: "#ffffff"
        },
        disabled:{
          textColor: "#dedede",
          borderColor: "#dedede",
          background: "#ffffff"
        }
      },
      "default-inverse": {
        normal:{
          textColor: "#7B3572",
          borderColor: "#ffffff",
          background: "#ffffff"
        },
        hover:{
          textColor: "#ffffff",
          borderColor: "#ffffff",
          background: "#7B3572"
        },
        active:{
          textColor: "#ffffff",
          borderColor: "#ffffff",
          background: "#7B3572"
        },
        disabled:{
          textColor: "#dedede",
          borderColor: "#dedede",
          background: "#ffffff"
        }
      },
      "default-o": {
        normal:{
          textColor: "#7B3572",
          borderColor: "#7B3572",
          background: "#ffffff"
        },
        hover:{
          textColor: "#ffffff",
          borderColor: "#7B3572",
          background: "#7B3572"
        },
        active:{
          textColor: "#ffffff",
          borderColor: "#7B3572",
          background: "#7B3572"
        },
        disabled:{
          textColor: "#dedede",
          borderColor: "#dedede",
          background: "#ffffff"
        }
      },
      "event-o": {
        normal:{
          textColor: "#079192",
          borderColor: "#079192",
          background: "#ffffff"
        },
        hover:{
          textColor: "#ffffff",
          borderColor: "#079192",
          background: "#079192"
        },
        active:{
          textColor: "#ffffff",
          borderColor: "#079192",
          background: "#079192"
        },
        disabled:{
          textColor: "#dedede",
          borderColor: "#dedede",
          background: "#ffffff"
        }
      },
      "subareas": {
        normal:{
          textColor: "#777878",
          borderColor: "#e9ebeb",
          background: "#e9ebeb"
        },
        hover:{
          textColor: "#7B3572",
          borderColor: "#e4e1e6",
          background: "#e4e1e6"
        },
        active:{
          textColor: "#7B3572",
          borderColor: "#e4e1e6",
          background: "#e4e1e6"
        },
        disabled:{
          textColor: "#dedede",
          borderColor: "#e9ebeb",
          background: "#e9ebeb"
        }
      },
    }
  },
  labelledValue: {
    textFont: null,
    textSize: 15,
    textWeight: 700,
    textLineHeight: null,
    textLetterSpacing: null,
    textTransform: "uppercase",
    borderSize: 1,
    borderStyle: "solid",
    variant:{
      "default": {
        textColor: "#7B3572",
        borderColor: "#7B3572"
      }
    }

  },
  badge:{
    padding: {
      top:{
        value: 2,
        unit: "px"
      },
      left:{
        value: 10,
        unit: "px"
      },
      bottom:{
        value: 2,
        unit: "px"
      },
      right:{
        value: 10,
        unit: "px"
      }
    },
    textFont: null,
    textSize: 11,
    textWeight: 700,
    textLineHeight: null,
    textLetterSpacing: null,
    textTransform: "uppercase",
    borderSize: 0,
    borderStyle: "solid",
    borderRadius: {
      value: 15,
      unit: "px"
    },
    variant:{
      "default": {
        textColor: "#585858",
        borderColor: "transparent",
        background: "#F2F4F4"
      },
      "event": {
        textColor: "#079192",
        borderColor: "transparent",
        background: "#07919217"
      },
      "filter": {
        textColor: "#585858",
        borderColor: "transparent",
        background: "#ffffff"
      },
      "primary": {
        textColor: "#ffffff",
        borderColor: "transparent",
        background: "#7B3572"
      },
      "primary-faded": {
         textColor: "#7B3572",
         borderColor: "transparent",
         background: "#ebdfe7"
       }
    }
  },

  menuitem:{

    textFont: null,
    textTransform: null,


    size: {
      "default": {
        textSize: null,
        textWeight: null,
        textLineHeight: null,
        textLetterSpacing: null,
        borderSize: 1,
        borderStyle: "solid",
        height:{
          value: 50,
          unit: "px"
        },
        iconSize: {
          value: 22,
          unit: "px"
        },
        padding: {
          top:{
            value: 15,
            unit: "px"
          },
          left:{
            value: 20,
            unit: "px"
          },
          bottom:{
            value: 15,
            unit: "px"
          },
          right:{
            value: 20,
            unit: "px"
          }
        },
      },
      "tiny":{
        textSize: 14,
        textWeight: 600,
        textLineHeight: null,
        textLetterSpacing: null,
        borderSize: 0,
        borderStyle: "solid",
        height:{
          value: 30,
          unit: "px"
        },
        iconSize: {
          value: 20,
          unit: "px"
        },
        padding: {
          top:{
            value: 20,
            unit: "px"
          },
          left:{
            value: 20,
            unit: "px"
          },
          bottom:{
            value: 20,
            unit: "px"
          },
          right:{
            value: 20,
            unit: "px"
          }
        },
      }
    },
    variant:{
      "default": {
        normal:{
          textColor: "#585858",
          background: "#ffffff",
          borderColor: "#F2F4F4"
        },
        hover:{
          textColor: "#585858",
          background: "#f4f4f4",
          borderColor: "#F2F4F4"
        },
        active:{
          textColor: "#585858",
          background: "#f4f4f4",
          borderColor: "#F2F4F4"
        },
        disabled:{
          textColor: "#999999",
          background: "#ffffff",
          borderColor: "#F2F4F4"
        }
      },
      "grey": {
        normal:{
          textColor: "#585858",
          background: "#F2F4F4",
          borderColor: "transparent"
        },
        hover:{
          textColor: "#585858",
          background: "#F2F4F4",
          borderColor: "transparent"
        },
        active:{
          textColor: "#585858",
          background: "#F2F4F4",
          borderColor: "transparent"
        },
        disabled:{
          textColor: "#999999",
          background: "#F2F4F4",
          borderColor: "transparent"
        }
      },
      "inverse": {
        normal:{
          textColor: "#ffffff",
          background: "#7B3572",
          borderColor: "transparent"
        },
        hover:{
          textColor: "#ffffff",
          background: "#7B3572",
          borderColor: "transparent"
        },
        active:{
          textColor: "#ffffff",
          background: "#7B3572",
          borderColor: "transparent"
        },
        disabled:{
          textColor: "#999999",
          background: "#7B3572",
          borderColor: "transparent"
        }
      },
      "filter": {
        normal:{
          textColor: "#585858",
          background: "transparent",
          borderColor: "#A3A3A3"
        },
        hover:{
          textColor: "#585858",
          background: "transparent",
          borderColor: "#A3A3A3"
        },
        active:{
          textColor: "#585858",
          background: "transparent",
          borderColor: "#A3A3A3"
        },
        disabled:{
          textColor: "#999999",
          background: "transparent",
          borderColor: "#A3A3A3"
        }
      },
      "homepage": {
        normal:{
          textColor: "#A3A3A3",
          background: "#EDEDED",
          borderColor: "#A3A3A3"
        },
        hover:{
          textColor: "#7B3572",
          background: "#ffffff",
          borderColor: "#A3A3A3"
        },
        active:{
          textColor: "#7B3572",
          background: "#ffffff",
          borderColor: "#A3A3A3"
        },
        disabled:{
          textColor: "#999999",
          background: "#EDEDED",
          borderColor: "#A3A3A3"
        }
      },
    }
  },
  colors: {
    primary: '#7B3572'
  }
};
