import React from 'react'
import {useHistory, useParams} from "react-router-dom";
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactHtmlParser from 'react-html-parser';
import Icon from "../../../components/App/Icon";
import {getTranslations} from "./../../../store/localization/selectors";

// import { useTheme } from "./../../../themes";
import { getConfig } from "./../../../store/settings/selectors";

import ToggleFavoriteButton from "./../../../components/User/ToggleFavoriteButton";


const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 10px 0px 0px;
  padding: 15px 15px;
  /*background-color: ${props => props.isActive ? "#ffffff" : "#F8F9F9"};*/
  border: 1px solid #818181;
  border-radius: 12px;
  ${props => !props.isActive ? `
    &:hover{
      background-color: #ffffff;
    }
  ` : ""};



`;
const Subwrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

`;
// const Left = styled.div`
//   flex-basis: 50px;
//   flex-grow: 0;
//   flex-shrink: 0;
//   text-align: center;
// `;
const Center = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
`;
const Right = styled.div`
  flex-basis: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
`;

const UpperText = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: #585858;

  & em{
    font-style: normal;
    border-bottom: 2px solid #585858;
  }
`;
const TitleText = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #E4018E;
  padding: 6px 0px;

  & em{
    font-style: normal;
    border-bottom: 2px solid #585858;
  }
`;
const LowerText = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 23px;
  color: #585858;

  & em{
    font-style: normal;
    border-bottom: 2px solid #585858;
  }
`;
export const WrapperBottom = styled.div`
  width: 100%;
  text-align: right;

  div {
    display:inline-block;
    margin-left: 10px;
  }
`;
export const Tag = styled.div`
  display: inline-block;
  margin-left: 10px;
  background: #000;
  border-radius: 4px;
  padding: 1px 6px 1px 6px;
  font-size: 9px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffffff;
  background: #E4018E;

  &.bordered {
    background: transparent;
    border: 2px solid #707070;
  }
`;

const CalendarButton = styled.button`
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
`;


function CompanyResult({
    item,
    isActive,
    isFavorite,
    url,
    resultType
  }) {
  const config = useSelector(getConfig);
  const translations = useSelector(getTranslations);
  let history = useHistory();
  let { lang } = useParams();

  function eventLinkHandler(e) {
    e.preventDefault();
    history.push(`${url}#events`)

  }

  return (

    <Wrapper isActive={isActive}>
      <Subwrapper>
        {/*<Left></Left>*/}
        <Center>
          { (item.servicesData && item.servicesData.location && item.servicesData.location.hallAndBooth && item.servicesData.location.hallAndBooth.length > 0) && config.enable_halls ?
            <UpperText>{
              item.servicesData.location.hallAndBooth.map((item, index) =>
                item.hallName[lang] + (item.booth ? " " + translations["UI"]["Stand"] + " " + item.booth : "")
              ).join(", ").length > 35 ?
                item.servicesData.location.hallAndBooth.map((item, index) =>
                  item.hallName[lang] + (item.booth ? " " + translations["UI"]["Stand"] + " " + item.booth : "")
                ).join(", ").slice(0, 35) + "..."
              :
                item.servicesData.location.hallAndBooth.map((item, index) =>
                  item.hallName[lang] + (item.booth ? " " + translations["UI"]["Stand"] + " " + item.booth : "")
                ).join(", ")
            }</UpperText>
          : ""}
          <TitleText isSuggested={resultType === "suggested"}>{ReactHtmlParser(item.highlight && item.highlight["indexData.name"].length > 0 ? item.highlight["indexData.name"][0] : item.options.name)}</TitleText>
          { item.options.locations.length > 0 ?
            <LowerText>{ReactHtmlParser(item.options.locations[0].countryId[lang] + (item.options.locations[0].regionId ? " | " + item.options.locations[0].regionId[lang] : "")  + (item.options.locations[0].provinceId ? " | " + item.options.locations[0].provinceId[lang] : ""))}</LowerText>
          : ""}
        </Center>
        { config.enable_login && config.enable_favorites ?
          <Right>
            {config.areas["events"].enable && item.eventsCount > 0 ? <CalendarButton onClick={eventLinkHandler}><Icon size={{value: 30, unit: 'px'}} name={'calendar-1'} color={'#079192'}/></CalendarButton> : null}
            <ToggleFavoriteButton slug={item.id} area={"companies"} size={{value: 30, unit:"px"}} colorOn={"#E4018E"} colorOff={"#dedede"} />
          </Right>
        : "" }
      </Subwrapper>
      {item.tags && item.tags.length ? (
        <WrapperBottom>
          {item.tags.map(tag => <Tag key={`${item.slug}-bottom-${tag}`}>{translations["UI"]["_badge-"+tag]}</Tag>)}
        </WrapperBottom>
      ) : null}
    </Wrapper>

  );
}

CompanyResult.propTypes = {
  item: PropTypes.object,
  isActive: PropTypes.bool,
  isFavorite: PropTypes.bool
}

export default React.memo(CompanyResult)
