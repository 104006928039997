import { combineReducers } from 'redux'
// import { connectRouter } from 'connected-react-router'
import authReducers from './auth/reducers'
import settingsReducers from './settings/reducers'
import localizationReducers from './localization/reducers';
import companiesReducers from './companies/reducers';
import productsReducers from './products/reducers';
import eventsReducers from './events/reducers';
import casehistoriesReducers from './casehistories/reducers';
import subcompaniesReducers from './subcompanies/reducers';
import subcasehistoriesReducers from './subcasehistories/reducers';
import chatReducers from './chat/reducers';
import bcardReducers from './bcard/reducers';

// import {i18nState} from "redux-i18n"

export default (history) => combineReducers({
    auth: authReducers,
    settings: settingsReducers,
    localization: localizationReducers,
    companies: companiesReducers,
    products: productsReducers,
    events: eventsReducers,
    casehistories: casehistoriesReducers,
    subcompanies: subcompaniesReducers,
    subcasehistories: subcasehistoriesReducers,
    chat: chatReducers,
    bcard: bcardReducers
})
