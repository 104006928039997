import React from 'react';
// import {Link} from 'react-router-dom';
import styled from "styled-components";
// import {useSelector} from "react-redux";
// import {getLocale} from "../../../store/localization/selectors";
import {useTheme} from "../../../themes";
import Image from "./../../../components/App/Image";
import Icon from "./../../../components/App/Icon";
import Proportional from "./../../../components/App/Proportional";
// import Loading from "./../../../components/App/Loading";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  // border: 1px solid #E5087E;
  // border-radius: 5px;
  overflow: hidden;
  padding-bottom: 10px;
  margin-top: 10px;
`;
const ImageCnt = styled.div`
  // border-radius: 5px;
  overflow: hidden;
  background-color: #dedede;
`;
// const Upper = styled.div`
//   color: #E5087E;
//   font-size: 10px;
//   margin-top: 5px;
//   margin-left: 10px;
//   margin-right: 10px;
// `;
const Title = styled.div`
  position: relative;
  color: #7B3572;
  font-size: 16px;
  margin-top: 10px;
  font-weight: 600;
  // margin-left: 10px;
  // margin-right: 10px;
  // border: 1px solid red;
  padding-right: 35px;
`;
// const Lower = styled.div`
//   margin-left: 10px;
//   margin-right: 10px;
// `;
const Button = styled.div`
  position: absolute;
  top: -5px;
  right: 0px;
  display: block;
  width: 30px;
  height: 30px;

  &::after{
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 2px;
    height: 70px;
    background-color: #7B3572;

  }
`;

export default function HomeProduct({
  image = null,
  abstract = null,
  content = null,
  ctaText = null,
  ctaLink = null,
  onClick = () => null
}){

  // const lang = useSelector(getLocale);
  const theme = useTheme();

  return (
    <Wrapper>
      <a href={ctaLink}>
        <ImageCnt>
          <Proportional ratio={2}>
            <Image
              src={image ? image : theme.placeholders.product}
              height={{"value": 100, "unit": "%"}}
              width={{"value": 100, "unit": "%"}}
              fit={"cover"}
            />
          </Proportional>
        </ImageCnt>
        {/* <Upper>{abstract}</Upper> */}
        {ctaText ? 
          <Title> 
            {ctaText}
            <Button>
              <Icon name={'arrow-right-2'} color={"#7B3572"} size={{ value: 30, unit: 'px'}} />
            </Button>
          </Title>
        : null}
        {/*<Lower>{ReactHtmlParser(content)}</Lower>*/}
      </a>
    </Wrapper>
  );

}
