import { createSelector } from 'reselect'

export const getItems = createSelector(
  (state) => state.items,
  items => items
);

export const getFilters = createSelector(
  (state) => state.subcasehistories,
  ({filters}) => filters
);

export const getCount = createSelector(
  (state) => state.subcasehistories.total_count,
  (total_count) => total_count
);

export const getFiltersSchema = createSelector(
  (state) => state.subcasehistories,
  ({filtersSchema}) => filtersSchema
);

