import produce from "immer"
import { handleActions } from 'redux-actions';
import {login, check, logout, setFavorite, getFavorites, setRecents, profile, setRoles} from './actions'

const INITIAL_REQUEST_STATE = {
  login: { loading: false, error: null},
  check: { loading: false, error: null},
  logout: { loading: false, error: null},
  setFavorite: { loading: false, error: null},
  getFavorites: { loading: false, error: null},
}

const INITIAL_STATE = {
  user: null,
  favorites: null,
  requests: INITIAL_REQUEST_STATE,
  recents: null,
  roles: [],
  permissions: [],
  companyData: null
}

const reducer = handleActions({

  [login.TRIGGER]: (state) => produce(state, draft => {
    draft.requests.login.loading = true
    draft.requests.login.error = null
  }),

  [login.SUCCESS]: (state, { payload }) => produce(state, draft => {
    draft.user = payload
    draft.requests.login.loading = false
  }),

  [login.FAILURE]: (state, { payload }) => produce(state, draft => {
    draft.requests.login.loading = false
    draft.requests.login.error = payload
  }),

  [check.TRIGGER]: (state) => produce(state, draft => {
    // draft.requests.check.loading = true
    // draft.requests.check.error = null
  }),

  [check.SUCCESS]: (state, { payload }) => produce(state, draft => {
    draft.user = payload
    // draft.requests.check.loading = false
  }),

  [check.FAILURE]: (state, { payload }) => produce(state, draft => {
    draft.user = null;
    draft.favorites = null;
    draft.roles = [];
    draft.permissions = [];
  }),

  [logout.TRIGGER]: (state) => produce(state, draft => {
    // draft.requests.logout.loading = true
    // draft.requests.logout.error = null
  }),

  [logout.SUCCESS]: (state, { payload }) => produce(state, draft => {
    draft.user = null;
    draft.favorites = null;
    draft.roles = [];
    draft.permissions = [];
    // draft.requests.logout.loading = false
  }),

  [logout.FAILURE]: (state, { payload }) => produce(state, draft => {
    draft.requests.logout.loading = false
    // draft.requests.logout.error = payload
  }),

  [setFavorite.TRIGGER]: (state) => produce(state, draft => {
    draft.requests.setFavorite.loading = true
    draft.requests.setFavorite.error = null
  }),

  [setFavorite.SUCCESS]: (state, { payload }) => produce(state, draft => {
    if(payload.status === true){
      if(draft.favorites === undefined){
        draft.favorites = [];
      }
      draft.favorites.push(payload.item)
    }else{
      draft.favorites = draft.favorites.filter(item => item.itemType !== payload.item.itemType || item.itemId !== payload.item.itemId);
      // draft.favorites.splice(draft.favorites.indexOf(payload.item), 1);
    }
    draft.requests.setFavorite.loading = false
  }),

  [setFavorite.FAILURE]: (state, { payload }) => produce(state, draft => {
    draft.requests.setFavorite.loading = false
    draft.requests.setFavorite.error = payload
  }),

  [getFavorites.TRIGGER]: (state) => produce(state, draft => {
    draft.requests.getFavorites.loading = true
    draft.requests.getFavorites.error = null
  }),

  [getFavorites.SUCCESS]: (state, { payload }) => produce(state, draft => {
    draft.favorites = payload
    draft.requests.getFavorites.loading = false
  }),

  [getFavorites.FAILURE]: (state, { payload }) => produce(state, draft => {
    draft.requests.getFavorites.loading = false
    draft.requests.getFavorites.error = payload
  }),

  [setRecents.SUCCESS]: (state, { payload }) => produce(state, draft => {
    draft.recents = payload
  }),

  [profile.SUCCESS]: (state, { payload }) => produce(state, draft => {
    draft.roles = payload.roles;
    draft.permissions = payload.permissions;
    draft.companyData = payload.companyData;
  }),

  [setRoles.TRIGGER]: (state, { payload }) => produce(state, draft => {
    draft.roles = payload;
  }),

}, INITIAL_STATE)

export default reducer
