import React from 'react';
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getIfMobile } from "../../../store/settings/selectors";
import Button from "../../../components/App/Button";
import Icon from "../../../components/App/Icon";
import Logo from '../../../components/App/Logo';

const Column = styled.div`
  width: calc(100% / 3);
  padding: ${props => props.isList ? '21px 0 20px 0' : '20px 0'};
  background-color: ${props => props.isList ? 'transparent' : '#fff'};
  border: ${props => props.isList ? '' : '1px solid #DDDDDD'};
  border-radius: ${props => props.isList ? '0' : '18px'};
  margin: ${props => props.isList ? '0' : '0 10px'};
  margin-bottom: 30px;

  @media(max-width: 991px) {
    ${props => props.isList ? 'display: none;' : ''}
    ${props => !props.isList ? 'width: calc(100% - 20px);' : ''}
  }
`;

const ColumnRow = styled.div`
  position: relative;
  border-bottom: 1px solid #DDDDDD;
  display: flex;
  align-items: center;
  height: 50px;
  padding: ${props => !props.isList ? '0 20px' : '0'};
  font-weight: bold;

  &::before,
  &::after {
    content: "";
    display: ${props => !props.isMobile && !props.isList ? 'block' : 'none'};
    width: 11px;
    height: 1px;
    background: #ddd;
    position: absolute;
    bottom: -1px;
    right: 100%;
  }
  &::after {
    right: auto;
    left: 100%;
    display: ${props => props.showLine ? 'block' : 'none'};
  }

  @media(max-width: 991px) {
  }

  justify-content: ${(props => !props.isList ? 'center' : '')};

  @media(max-width: 991px) {
    justify-content: space-between;
  }
`;

const ColumnHead = styled.div`
  text-align: center;
  line-height: normal;
  height: 100px;
`;

const Value = styled.div`
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 18px;
    background: #7B3572;
    opacity: ${props => props.active ? 1 : 0.13};
`;

const Title = styled.div`
    font-size: 33px;
    color: #7B3572;
`;

const Subtitle = styled.div`
    color: #000;
    font-size: 18px;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 60px;

  > button {
    margin: 0 auto;
    width: 140px;
    height: 40px;
  }
`;

const ActiveButton = styled.div`
  padding: 0;
  border: 2px solid #079192;
  width: 140px;
  height: 40px;
  margin: 0 auto;
  border-radius: 20px;
`;

const ChildBox = styled.div`
  /* border: 1px solid #DDDDDD;
  background-color: #ffffff88; */
  padding: 30px;
  text-align: center;
  border-radius: 18px;
  width: 400px;
  /* margin-left: 30px; */
`;

const ChildBoxLogo = styled.div`
`;
const ChildBoxContent = styled.div`
  color: #7B3572;
`;


export default function ListColumn({ onClick, title = '@Column', subtitle = null, isList = false, isText = false, isLast = false, items = [], actives = [0,0,0,0,0,0], isSelected = false, signInLabel = 'Accedi', children }) {

  const isMobile = useSelector(getIfMobile);

  return (
    <Column isList={isList || isText}>
      {/*!isList ? (
        <ColumnHead>
          <Title>{title}</Title>
          {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
        </ColumnHead>
      ) : null*/}
      {items.map((item, index) => (
        <ColumnRow key={`column-item-${index}`} isMobile={isMobile} isList={isList} showLine={!isMobile && !isLast && !isList}>
          {isList || isMobile ? (
            <div className="_label">{item}</div>
          ) : null}
          {!isList ? <Value active={actives[index]} /> : null}
        </ColumnRow>
      ))}
      {!isText && !isList ? (
        <ButtonContainer>
          {isSelected ? (
            <ActiveButton>
              <Icon styles={{marginTop: '8px'}} name='tick-1' size={{ value: 20, unit: 'px'}} color={'#079192'} />
            </ActiveButton>
          ) : <Button variant="default-o" onClick={onClick}>{signInLabel}</Button>}
        </ButtonContainer>
      ) : null}
      {isText ?
        <>
          <ChildBox>
            <ChildBoxLogo>
              <Logo width={{value: 110, unit: "px"}} variant="plus" />
            </ChildBoxLogo>
            <ChildBoxContent>
              {children}
            </ChildBoxContent>
          </ChildBox>
        </>
       : null}
    </Column>
  );
}
